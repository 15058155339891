<div
  class="d-flex flex-column flex-md-row input-btn-group"
  *ngIf="largeView || !selectedEvent"
>
  <div class="flex-md-grow-1 mb-2 mb-md-0">
    <gtapp-auto-complete
      [data]="feedbackDropDownList"
      placeHolder="Search"
      searchKeyword="company_name"
      [itemTemplate]="feedbcakTemplate"
      (inputChanged)="onFeedBackLookup($event)"
      (selected)="onRowClick($event)"
      (submitted)="globalFilter = $event; getFeedbacks()"
    >
    </gtapp-auto-complete>

    <ng-template #feedbcakTemplate let-item>
      <div class="small">{{ item?.feedback }}</div>
    </ng-template>
  </div>
  <div class="ms-md-2 mb-2 mb-lg-0">
    <button
      class="btn btn-primary btn-lg w-100"
      (click)="openAddFeedbackForm(feedbackRef)"
    >
      + New
    </button>
  </div>
  <div class="ms-md-2 mb-2 mb-lg-0">
    <button class="btn btn-secondary btn-lg w-100" (click)="showAllFeedbacks()">
      {{ showDefaultFeedbacks ? "Show All" : "Reset" }}
    </button>
  </div>
</div>

<div
  class="row row-cols-1"
  [ngClass]="selectedEvent ? 'row-cols-lg-2' : 'row-cols-lg-1'"
>
  <div class="col">
    @if(largeView){

    <gtapp-gt-table
      [desktopTableBackendData]="feedbacks"
      [desktopTableDetails]="deskTopTableDetail"
      [tableStyle]="tableStyle"
      (sortColumn)="sortColumn($event)"
      [tableStyle]="tableStyle"
      (paginationChange)="onChangePagination($event)"
      (rowClick)="onRowClick($event)"
    ></gtapp-gt-table>

    }@else {
    <gtapp-card-list
      [mobileCardBackendData]="selectedEvent ? [selectedEvent] : feedbacks"
      [mobileCardDetails]="mobileTableDetail"
      [otherParams]="selectedEvent ? {} : otherParams"
      (rowClick)="onRowClick($event)"
      (sortColumn)="sortColumn($event)"
      (paginationChange)="onChangePagination($event)"
    ></gtapp-card-list>

    }
  </div>
  <div class="col">
    <div *ngIf="selectedEvent" class="mt-4">
      <!-- Comment section -->
      <div *ngIf="selectedEvent?.updates?.length > 0" class="list-fixed-length">
        <div *ngFor="let update of selectedEvent?.updates">
          <dl>
            <dt>
              {{ update?.updated_at | customDate : "d MMM y HH:mm" }} |
              {{
                update?.updated_by?.is_superuser
                  ? "Support Team"
                  : update?.updated_by?.full_name
              }}
            </dt>
            <dd *ngIf="update?.event_action?.key === 1">
              {{
                update?.description === "null" ||
                update?.description === "undefined"
                  ? ""
                  : update?.description
              }}
            </dd>
            <dd class="chat-data" *ngIf="update?.event_action?.key === 0">
              {{
                update?.updated_by?.is_superuser
                  ? "Support Team"
                  : update?.updated_by?.full_name
              }}
              changed status to
              {{ update?.status?.value }}

              <div>
                Reason:
                {{
                  update?.description === "null" ||
                  update?.description === "undefined"
                    ? ""
                    : update?.description
                }}
              </div>
            </dd>
          </dl>
        </div>
      </div>
      <div class="row-cols-1 gy-2" *ngIf="selectedEvent?.status != 'COMPLETED'">
        <div class="col">
          <textarea
            rows="3"
            class="form-control"
            type="text"
            placeholder="Add comment"
            [(ngModel)]="newComment"
          ></textarea>
        </div>
      </div>
      <div class="row row-cols-2 mt-3">
        <div class="col">
          <button
            (click)="openTemplate(deleteRef)"
            class="btn btn-lg btn-outline-danger btn-lg w-100"
          >
            Delete
          </button>
        </div>
        <div class="col">
          <button
            (click)="addComment()"
            class="btn btn-lg btn-primary btn-lg w-100"
            [disabled]="!newComment"
          >
            Send
          </button>
        </div>
      </div>
      <div class="row mt-3 bottom-section" id="fixedBtn">
        <div class="px-3 pb-2">
          <button
            class="btn btn-dark d-dark d-md-none w-100 btn-lg"
            (click)="hideDetails()"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Image display modal -->
<ng-template #imgRef let-data let-imgTempRef="dialogRef">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body" (click)="imgTempRef.close()">
        <img
          src="{{ fullImgSrc }}"
          style="
            max-width: 100%;
            cursor: pointer;
            max-height: 95vh;
            border-radius: 2%;
          "
          class="modal-hover-opacity"
        />
      </div>
    </div>
  </div>
</ng-template>

<!-- Delete feedback dialog -->
<ng-template #deleteRef let-deleteRef="dialogRef">
  <div class="card dialog-box">
    <div class="card-header">
      Delete Submission
      <span class="float-end" (click)="deleteRef.close()"
        ><i class="fa-solid fa-xmark"></i
      ></span>
    </div>
    <div class="form-floating">
      <input
        class="form-control"
        type="text"
        [(ngModel)]="deleteReason"
        placeholder="Reason"
      />
      <label>Reason</label>
    </div>
    <div class="row row-cols-2 mt-4">
      <div class="col">
        <button class="btn btn-dark btn-lg w-100" (click)="deleteRef.close()">
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          class="btn btn-danger btn-lg w-100"
          [disabled]="!deleteReason"
          (click)="openTemplate(deleteRef)"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</ng-template>
<!-- ADD FEEDBACK POPOVER / DIALOG -->
<ng-template #feedbackRef let-feedbackRef="dialogRef">
  <div class="card dialog-box">
    <div class="card-header">
      Contact Form

      <span
        class="float-end"
        (click)="
          feedbackRef.close('close'); showHideDraggableFeedbackBtn(false)
        "
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div class="p-1">
      <form [formGroup]="feedbackForm" class="row-cols-1 gy-2">
        <div class="col">
          <textarea
            class="form-control"
            cols="32"
            rows="10"
            wrap="hard"
            maxlength="340"
            placeholder="Need help or have feedback? Please tell us how we can help."
            formControlName="feedback"
          ></textarea>
        </div>
      </form>
    </div>
    <div class="row row-cols-2 mt-4">
      <div class="col">
        <button
          class="btn btn-dark btn-lg w-100"
          (click)="
            feedbackRef.close('close'); showHideDraggableFeedbackBtn(false)
          "
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          (click)="
            addFeedback();
            feedbackRef.close();
            showHideDraggableFeedbackBtn(false)
          "
          class="btn btn-primary btn-lg w-100"
          [disabled]="!feedbackForm.valid"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</ng-template>
