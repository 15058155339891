<div class="mb-2 fw-bold">
  <span>
    {{ selectedStatus?.name ? selectedStatus?.name : ""
    }}<span *ngIf="formdata?.value?.name"
      >: {{ formdata.value?.name }}</span
    ></span
  >
</div>
<form [formGroup]="formdata">
  <div
    class="row row-cols-1"
    [ngClass]="currentStep === 2 ? 'row-cols-lg-3' : 'row-cols-lg-2'"
  >
    <div class="col">
      <ng-container *ngIf="currentStep === 1">
        <div class="row g-2 mb-2">
          <div class="form-floating">
            <input
              autofocus
              id="mobnameField"
              class="form-control"
              type="text"
              placeholder="Name"
              autocomplete="off"
              formControlName="name"
            />
            <label for="mobnameField">Name</label>
          </div>
        </div>

        <div class="row row-cols-2 g-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              [routerLink]="['/roster']"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="!formdata.value?.name"
              (click)="getPatrolRouteCheckpoints()"
            >
              Next
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep === 2">
        <div>
          <div class="row row-cols-2 g-2" *ngIf="!largeView">
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-dark w-100"
                (click)="
                  patrolRouteCPList = [];
                  currentStep = 1;
                  patrolRouteCpRows = 10;
                  patrolRouteCpPrevious = 0;
                  patrolRouteCpPageNum = 1;
                  patrolRouteCpTotalPages = 0
                "
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-primary w-100"
                [disabled]="!patrolJobType?.checkpoints?.data?.length"
                (click)="
                  assigneeList = []; getPatrolRouteAssignees(); currentStep = 3
                "
              >
                Next
              </button>
            </div>
          </div>
          <div class="mt-3" *ngIf="!largeView">
            <!-- Tabs Navigation for mobile view-->
            <ul class="nav nav-tabs" id="checkpointsTab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="all-checkpoints-tab"
                  data-bs-toggle="tab"
                  href="#all-checkpoints"
                  role="tab"
                  aria-controls="all-checkpoints"
                  aria-selected="true"
                  >Checkpoints</a
                >
              </li>
              <li
                class="nav-item"
                role="presentation"
                *ngIf="patrolJobType?.checkpoints?.data?.length > 0"
              >
                <a
                  class="nav-link"
                  id="selected-checkpoints-tab"
                  data-bs-toggle="tab"
                  href="#selected-checkpoints"
                  role="tab"
                  aria-controls="selected-checkpoints"
                  aria-selected="false"
                  >Added
                  <span class="badge bg-primary ms-1">{{
                    patrolJobType?.checkpoints?.data?.length
                  }}</span>
                </a>
              </li>
            </ul>

            <!-- Tabs Content -->
            <div class="tab-content" id="checkpointsTabContent">
              <!-- All Checkpoints Tab -->
              <div
                class="tab-pane fade show active"
                id="all-checkpoints"
                role="tabpanel"
                aria-labelledby="all-checkpoints-tab"
              >
                <div *ngIf="patrolRouteCpCount > 10" class="form-floating my-2">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Filter Checkpoints"
                    (input)="searchCheckPoints($event)"
                  />
                  <label>Checkpoint Name</label>
                </div>

                <div class="overflow-cps" id="cpList">
                  <table class="w-100">
                    <tbody>
                      <tr
                        *ngFor="let checkpoint of patrolRouteCPList"
                        class="pointer"
                      >
                        <td>
                          <div class="d-flex align-items-center">
                            <div class="flex-grow-1">
                              {{ checkpoint?.name }}
                              <div class="small">
                                {{ checkpoint?.site_name }} [{{
                                  checkpoint?.company
                                }}]
                              </div>
                              <div class="small text-muted">
                                {{ checkpoint?.address?.full_address }}
                              </div>
                            </div>
                            <div *ngIf="checkpoint?.qr_code" class="me-2">
                              <img
                                src="assets/images/qr-code-logo.avif"
                                alt="QR Code"
                                width="26"
                                class="mb-0 pb-0"
                              />
                            </div>
                            <div>
                              <button
                                class="btn btn-primary btn-sm m-0"
                                (click)="addSelectedCP(checkpoint)"
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  class="my-2"
                  *ngIf="
                    patrolRouteCpTotalPages > 1 &&
                    patrolRouteCpTotalCount != patrolRouteCPList?.length
                  "
                >
                  <button
                    type="button"
                    class="btn btn-secondary btn-lg"
                    (click)="onLoadMore()"
                  >
                    Load More
                  </button>
                </div>
              </div>

              <!-- Selected Checkpoints Tab -->
              <div
                class="tab-pane fade"
                id="selected-checkpoints"
                role="tabpanel"
                aria-labelledby="selected-checkpoints-tab"
              >
                <div class="overflow-cps">
                  <table class="w-100">
                    <tbody
                      cdkDropList
                      (cdkDropListDropped)="dropCheckPoint($event)"
                    >
                      <tr
                        *ngFor="
                          let checkpoint of patrolJobType?.checkpoints?.data;
                          let i = index
                        "
                        class="pointer"
                      >
                        <td>
                          <div class="d-flex align-items-center">
                            <div class="me-2" cdkDrag>
                              <i class="fas fa-bars fa-2x"></i>
                            </div>
                            <div class="flex-grow-1">
                              {{ checkpoint?.name }} [{{ checkpoint?.company }}]
                              <div class="small text-muted">
                                {{ checkpoint?.address?.full_address }}
                              </div>
                            </div>
                            <div *ngIf="checkpoint?.qr_code" class="me-2">
                              <img
                                src="assets/images/qr-code-logo.avif"
                                alt="QR Code"
                                width="26"
                                class="mb-0 pb-0"
                              />
                            </div>
                            <div (click)="removeSelectedCP(i)">
                              <button class="btn btn-danger btn-sm m-0">
                                Del
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="largeView">
            <div class="form-floating my-2">
              <input
                class="form-control"
                type="text"
                placeholder="Filter Checkpoints"
                (input)="searchCheckPoints($event)"
              />
              <label>Filter Checkpoints</label>
            </div>
            <div class="overflow-cps">
              <table class="w-100">
                <tbody>
                  <tr
                    *ngFor="let checkpoint of patrolRouteCPList"
                    class="pointer"
                  >
                    <td>
                      <div class="d-flex align-items-center">
                        <div
                          class="flex-grow-1 user-select-none"
                          role="button"
                          (click)="updateMap(checkpoint)"
                        >
                          <div class="flex-grow-1">
                            {{ checkpoint?.name }} [{{ checkpoint?.company }}]
                            <div class="small text-muted">
                              {{ checkpoint?.address?.full_address }}
                            </div>
                          </div>
                          <div *ngIf="checkpoint?.qr_code" class="me-2">
                            <img
                              src="assets/images/qr-code-logo.avif"
                              alt="QR Code"
                              width="26"
                              class="mb-0 pb-0"
                            />
                          </div>
                        </div>
                        <div (click)="addSelectedCP(checkpoint)">
                          <button class="btn btn-primary btn-sm m-0">
                            Add
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="my-2"
              *ngIf="
                patrolRouteCpTotalPages > 1 &&
                patrolRouteCpTotalCount != patrolRouteCPList?.length
              "
            >
              <button
                type="button"
                class="btn btn-secondary btn-lg"
                (click)="onLoadMore()"
              >
                Load More
              </button>
            </div>
            <div class="row row-cols-2 g-2">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-lg btn-dark w-100"
                  (click)="
                    patrolRouteCPList = [];
                    currentStep = 1;
                    patrolRouteCpRows = 10;
                    patrolRouteCpPrevious = 0;
                    patrolRouteCpPageNum = 1;
                    patrolRouteCpTotalPages = 0
                  "
                >
                  Back
                </button>
              </div>
              <div class="col">
                <button
                  type="button"
                  class="btn btn-lg btn-primary w-100"
                  [disabled]="!patrolJobType?.checkpoints?.data?.length"
                  (click)="
                    assigneeList = [];
                    getPatrolRouteAssignees();
                    currentStep = 3
                  "
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep === 3">
        <div class="row row-cols-2 g-2 mb-3">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="assigneeList = []; currentStep = 2"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="!patrolJobType?.assignees?.data?.length"
              (click)="currentStep = 4"
            >
              Next
            </button>
          </div>
        </div>
        <div class="mb-2">Assign Patrol To</div>

        <div *ngIf="assigneeList.length > 10" class="mb-1">
          <gtapp-auto-complete
            [data]="assigneeSearchList"
            placeHolder="Guard Name"
            searchKeyword="full_name"
            [itemTemplate]="assigneeListTemplate"
            (inputChanged)="searchAssignee($event)"
            (selected)="addSelectedAssignee($event)"
          >
          </gtapp-auto-complete>
        </div>

        <ng-template #assigneeListTemplate let-item>
          <div>
            {{ item?.full_name }}
          </div>
        </ng-template>
        <div class="overflow-assignne" id="assigneeList">
          <table class="table my-2">
            <tbody>
              <tr
                *ngFor="let assignee of assigneeList"
                class="cursor-pointer"
                (click)="addRemovessignee(assignee)"
              >
                <td>
                  <div class="d-flex">
                    <div>
                      <div class="form-floating">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="assignee?.selected"
                        />
                      </div>
                    </div>
                    <div class="flex-grow-1 ms-2">
                      {{ assignee?.full_name }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="mt-3"
          *ngIf="
            patrolRouteAssigneeTotalPages > 1 &&
            patrolRouteAssigneeTotalCount != assigneeList?.length
          "
        >
          <button
            type="button"
            class="btn btn-secondary btn-lg"
            (click)="onLoadMoreAssignees()"
          >
            Load More
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep === 4">
        <div class="my-2">
          <textarea
            class="form-control"
            cols="20"
            rows="10"
            wrap="hard"
            maxlength="900"
            type="text"
            formControlName="patrol_brief"
            placeholder=" Patrol Information (Optional)"
          ></textarea>
        </div>

        <div class="d-flex my-2">
          <div
            class="d-flex align-items-center justify-content-between w-100"
            *ngIf="patrolJobType.patrol_brief_files.value"
          >
            <div class="flex-grow-1 fw-bold text-truncate pe-2">
              {{ patrolJobType.patrol_brief_files.value?.name }}
            </div>
            <div>
              <button
                type="button"
                class="btn btn-md btn-dark"
                (click)="patrolJobType.patrol_brief_files.value = null"
              >
                Remove File
              </button>
            </div>
          </div>

          <div class="w-100" *ngIf="!patrolJobType.patrol_brief_files.value">
            <button
              type="button"
              class="btn btn-md btn-secondary w-100 btn-lg"
              (click)="uploadFile()"
            >
              Upload File
            </button>
            <input
              #fileInput
              id="fileInput"
              style="display: none; cursor: pointer"
              type="file"
              accept="application/pdf, .jpg, .jpeg, .png, .avif"
              (change)="handleFileInput($event)"
            />
          </div>
        </div>

        <div class="row row-cols-2 my-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 3"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              (click)="formatScheduleForm(); currentStep = 5"
            >
              Next
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep === 5">
        <div class="row row-cols-2 my-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 4"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              (click)="onAddJob()"
              [disabled]="
                !patrolJobType.checkpoints?.data?.length ||
                !patrolJobType.assignees?.data?.length ||
                addJobPressed ||
                !schedulerForm.valid
              "
            >
              Add Job
            </button>
          </div>
        </div>
        <gtapp-scheduler
          *ngIf="scheduleStepperActive"
          [dialogBox]="false"
          [schedulerForm]="schedulerForm"
          [editSchedule]="true"
        ></gtapp-scheduler>
        <div class="mt-4 form-floating">
          <select
            class="form-select"
            (click)="getIntervalData()"
            [ngModel]="intervalData[0].value"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="updateInterval($event)"
            placeholder="Welfare Check Interval"
          >
            <option
              *ngFor="let interval of intervalData"
              [ngValue]="interval.value"
            >
              {{ interval.name }}
            </option>
          </select>
          <label for="">Welfare Check Interval</label>
        </div>
        <div *ngIf="intervalCheck" class="alert">
          <div class="mb-2">
            Guards will be reminded to submit a welfare check
            {{ welfareReminderDict?.[intervalCheck]  }} minutes before the
            interval set above.
          </div>
          <div>
            Maximum inactivity time allowed before a missed welfare check is
            captured:
            <span class="fst-italic"> {{ intervalCheck }} minutes. </span>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col mt-5 overflow-cps" *ngIf="largeView && currentStep === 2">
      <table class="w-100">
        <tbody cdkDropList (cdkDropListDropped)="dropCheckPoint($event)">
          <tr
            *ngFor="
              let checkpoint of patrolJobType?.checkpoints?.data;
              let i = index
            "
            class="pointer"
          >
            <td>
              <div class="d-flex align-items-center">
                <div class="me-2" cdkDrag>
                  <i class="fas fa-bars fa-2x"></i>
                </div>
                <div
                  class="flex-grow-1 user-select-none"
                  role="button"
                  (click)="updateMap(checkpoint)"
                >
                  <div class="flex-grow-1">
                    {{ checkpoint?.name }} [{{ checkpoint?.company }}]
                    <div class="small text-muted">
                      {{ checkpoint?.address?.full_address }}
                    </div>
                  </div>
                  <div *ngIf="checkpoint?.qr_code" class="me-2">
                    <img
                      src="assets/images/qr-code-logo.avif"
                      alt="QR Code"
                      width="26"
                      class="mb-0 pb-0"
                    />
                  </div>
                </div>
                <div (click)="removeSelectedCP(i)">
                  <button class="btn btn-danger btn-sm m-0">Del</button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--show map here-->
    <div class="col" *ngIf="largeView && currentStep === 2">
      <!-- <gtapp-map-pins-view
        [listData]="patrolJobType?.checkpoints?.data"
        [centerLatLon]="selectedLastCP | misc : 'latLonCenter'"
        mapFrom="desktopCpPage"
      ></gtapp-map-pins-view> -->
      <gtapp-map-leaflet
        [latLon]="{
          lat: selectedLastCP.latitude,
          lon: selectedLastCP.longitude
        }"
        [mapId]="'largeJob'"
      ></gtapp-map-leaflet>
    </div>
  </div>
</form>
