import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getFormattedDateTime } from '../../../../../global.variable';
import { AuthService } from '../../../../auth/auth.service';
import { AddEditClientComponent } from '../../../../shared/components/add-edit-client/add-edit-client.component';
import { AddEditIncidentTypeComponent } from '../../../../shared/components/add-edit-incident-type/add-edit-incident-type.component';
import { AddEditSiteComponent } from '../../../../shared/components/add-edit-site/add-edit-site.component';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { DataCheckService } from '../../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { ClientsService } from '../../clients/clients.service';
import { JobsService } from '../../jobs/jobs.service';
import { IncidentsService } from '../incidents.service';
type autoDropdownKey = 'clientSelected' | 'siteSelected' | 'incidentSelected';

@Component({
  selector: 'gtapp-add-incident',
  templateUrl: './add-incident.component.html',
  styleUrl: './add-incident.component.scss',
})
export class AddIncidentComponent implements OnInit {
  incidentDict: any = {
    client: {
      name: 'Client',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    site: { name: 'Site', value: '', edit: false, data: null, tempValue: '' },
    incidentType: {
      name: 'Incident Type',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },

    iTime: {
      name: 'I Time',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    iDay: {
      name: 'I Day',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
  };
  isAdmin: boolean = false;
  isDispatchUser: boolean = false;
  siteLicenceReminderCheck: boolean = false;
  licenseReminderField: boolean = false;
  showSiteMap: boolean = false;

  userData: any;

  dialogRef: any;

  siteList: any = [];
  addIncidentButtonPressed: boolean = false;
  incidentTypeList: any = [];
  todayDate: any = new Date();
  showClientDropdown: boolean = false;

  constructor(
    private incidentService: IncidentsService,
    private dialogService: ModelDialogueService,
    private customerService: ClientsService,
    private authService: AuthService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private spinnerService: LoadingSpinnerService,
    private jobService: JobsService,
    private toasterService: ToasterService
  ) {
    this.userData = this.authService.getUserData();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.siteLicenceReminderCheck = this.userData?.checks?.license_reminder;

    router.events.subscribe((event: any) => {
      // close dialog boxes if any are open when back is pressed
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });
  }

  ngOnInit(): void {
    this.incidentDict.iDay.value = getFormattedDateTime();
    this.getIncidentTypes();
    this.editInputField('client');
    this.siteLookup('');
  }
  siteLookup(val?: any) {
    this.customerService.getSites(val).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.siteList = res['data'];
      }
    });
  }
  onClientSelect(data: any) {
    if (data?.key === 0) {
      this.openAddCustomerForm();
    }
    if (data?.id) {
      this.clearValues('site');
      this.incidentDict.client.value = data?.company_name;
      this.incidentDict.client.data = data;

      this.editInputField('site');

      this.customerService
        .getSites({ company: data?.id, is_active: 1 })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.siteList = res['data'];
          }
        });
    }
  }
  getIncidentTypes(params?: any) {
    this.incidentService.fetchIncidentTypeList(params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.incidentTypeList = res['data'];
      }
    });
  }
  onSearchIncidentType(event: any) {
    this.incidentDict.incidentType.tempValue = event.target.value;
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.incidentTypeList?.length)
    ) {
      this.getIncidentTypes({ search_str: event?.target?.value });
    }

    this.incidentDict.incidentType.data = null;
  }
  onSelectIncidentType(event: any) {
    if (event.key === 0) {
      if (this.incidentDict.incidentType.tempValue.length > 0) {
        var requestData = { name: this.incidentDict.incidentType.tempValue };
        this.incidentService
          .createIncidentType(requestData)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                errorMessage: '',
                successMessage: response['message'],
              });
              this.incidentDict.incidentType.value = response['data'].name;
              this.incidentDict.incidentType.data = response['data'];
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      } else {
        this.toasterService.setMessage({
          errorMessage: "Incident Name can't be empty",
          successMessage: '',
        });
      }
    }
    if (event?.id) {
      this.incidentDict.incidentType.value = event?.name;
      this.incidentDict.incidentType.data = event;
      this.editInputField('iDay');
    }
  }
  openAddIncidentTypeForm() {
    const incidentTypeDialogRef = this.dialogService.open(
      AddEditIncidentTypeComponent,
      {
        data: {
          name: this.incidentDict.incidentType.tempValue,
        },
      }
    );

    incidentTypeDialogRef.afterClosed().subscribe((value: any) => {
      if (value !== 'close') {
        this.onSelectIncidentType(value?.data);
      }
    });
  }
  openAddCustomerForm() {
    this.dialogRef = this.dialogService.open(AddEditClientComponent, {
      data: { customerName: this.incidentDict.client.tempValue },
    });

    this.dialogRef.afterClosed().subscribe((value: any) => {
      if (value !== 'close') {
        this.onClientSelect(value?.data);
        // this.value = value?.data?.company_name;
      }
    });
  }

  clearValues(_field: string) {
    if (_field == 'client') {
      this.incidentDict.client = {
        ...this.incidentDict.client,
        ...{ value: '', data: null, tempValue: '' },
      };

      this.incidentDict.site = {
        ...this.incidentDict.site,
        ...{ value: '', data: null, tempValue: '' },
      };
    }
    if (_field == 'site') {
      this.incidentDict.site = {
        ...this.incidentDict.site,
        ...{ value: '', data: null, tempValue: '' },
      };
    }
  }

  onSiteSearch(event: any) {
    // in case add client auto fill the form
    this.clearValues('site');
    this.incidentDict.site.tempValue = event.target.value;
    if (event.target.value?.length > 2) {
      this.customerService
        .getSites({
          search_str: event.target.value,
          is_active: 1,
        })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.siteList = res['data'];
          }
        });
    }
  }
  onSelectSite(data: any) {
    if (data?.key === 0) {
      this.openAddSiteForm();
    }

    if (data?.id) {
      this.clearValues('oc');
      this.showSiteMap = true;
      this.incidentDict.site.value = data?.company_name;
      this.incidentDict.site.data = data;
      this.incidentDict.client.value = data?.company_name;
      this.incidentDict.client.data = data;

      this.editInputField('incidentType');
    }
  }
  openAddSiteForm() {
    if (this.incidentDict.client.data) {
      this.dialogRef = this.dialogService.open(AddEditSiteComponent, {
        data: {
          detailData: { company: this.incidentDict.client.data?.id },
          siteAddress: this.incidentDict.site.tempValue,
        },
      });
      this.dialogRef.afterClosed().subscribe((value: any) => {
        if (value !== 'close') {
          this.onSelectSite(value);
        }
      });
    } else {
      this.toasterService.setMessage({
        errorMessage: 'Please select a client first',
        successMessage: '',
      });
    }
  }
  onAddIncident() {
    this.spinnerService.show();
    this.addIncidentButtonPressed = true;
    let dateTime = new Date(this.incidentDict.iDay.value);

    let incidentData = {
      site_id: this.incidentDict?.site?.data?.id,
      incident_time: dateTime,
      incident_type: this.incidentDict.incidentType.data?.id,
    };
    this.incidentService.addIncident(incidentData).then((response: any) => {
      if (response['status'] == 'success') {
        if (response?.data)
          this.router.navigate([
            '/view-incident',
            { inKey: String(response?.data?.id) },
          ]);
      } else {
        this.addIncidentButtonPressed = false;
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }

      this.spinnerService.hide();
    });
  }

  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      if (['mobiTimeField', 'deskTimeField'].includes(elementId)) {
        element?.click();
      }

      element?.click();
      element?.focus();
    });
  }
  editInputField(field: string) {
    for (let key in this.incidentDict) {
      this.incidentDict[key]['edit'] = false;
    }
    this.incidentDict[field]['edit'] = true;
  }
  formateStartDate(event: any) {
    this.incidentDict.iDay.value = event.target.value;
  }

  convertTime12to24(time12h: any) {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours == '12') {
      hours = '00';
    }

    if (modifier.toUpperCase() === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }
}
