<div *ngIf="cameraError" class="alert alert-danger">
  {{ cameraError }}
</div>

<div [ngClass]="detailType === 'updateJob' ? '' : 'view-home-details-height'">
  <div
    [ngStyle]="{
      'padding-bottom':
        detailType !== 'updateJob' ? bottomSectionFixedHeight : '0px'
    }"
  >
    <!-- scan check points BEGIN -->
    <div *ngIf="detailType === 'scanCheckPoint'" class="mb-1">
      <div *ngIf="dupCheckpointList?.length == 0" class="my-3">
        <div class="alert alert-primary fw-bold">No Checkpoints Near You</div>
      </div>

      <div *ngIf="dupCheckpointList.length > 0">
        <div class="flex-grow-1">
          <div class="form-floating mb-3" *ngIf="!distanceCalculation">
            <input
              type="text"
              class="form-control"
              id="searchCheckpoints"
              placeholder="Search Checkpoints"
              [(ngModel)]="searchFilter"
              (keyup)="onCPFilterApply($event)"
            />
            <label for="searchCheckpoints">Search Checkpoints</label>
            <button
              class="btn btn-link p-0"
              *ngIf="showCPFilterClose"
              (click)="onCPClearFilter()"
            >
              <i class="bi bi-x-lg"></i>
            </button>
          </div>
        </div>

        <div *ngIf="apidataUpdation === true && !offlineMode">
          <div class="alert alert-primary my-3">
            <div class="fw-bold">
              Fetching Data from Server

              <div class="spinner-grow float-end" role="status"></div>
            </div>
          </div>
        </div>

        <div class="list-fixed-height row">
          <div
            class="gy-2"
            *ngFor="let checkPoint of nearestCheckPointsList"
            (click)="openCamPopup(checkPoint)"
          >
            <div class="card p-2">
              <div class="fw-bold">
                {{ checkPoint?.name }}
              </div>
              <div class="small">
                @if(checkPoint?.site_name){
                <div>
                  {{ checkPoint?.site_name }} [{{ checkPoint?.company }}]
                </div>
                }@else{
                <div>
                  {{ checkPoint?.company }}
                </div>
                }

                <div>
                  {{ checkPoint?.full_address }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- scan check points ENDS -->

    <!-- nearestJob list section start -->
    <div *ngIf="detailType === 'updateJob'">
      <div *ngIf="apidataUpdation === true && !offlineMode">
        <div class="alert alert-primary my-3">
          <div class="fw-bold">
            Fetching Data from Server

            <div class="spinner-grow float-end" role="status"></div>
          </div>
        </div>
      </div>
      <div *ngIf="isGuardUser">
        <div *ngIf="dupnearestJobList?.length == 0" class="my-3">
          <div class="alert alert-primary text-center">
            No Jobs Rostered Today
          </div>
          <button
            *ngIf="!offlineMode"
            class="btn btn-primary btn-lg btn-block w-100"
            routerLink="/roster"
          >
            View Roster
          </button>
        </div>
      </div>

      <div *ngIf="dupnearestJobList.length > 0">
        <div *ngIf="nearestJobList?.length" class="list-fixed-height row mx-1">
          <gtapp-card-list
            [mobileCardUIData]="nearestJobList"
            [mobileCardDetails]="jobCardsDetails"
            [otherParams]="{ normalCard: true }"
            (rowClick)="openEventPage($event)"
          ></gtapp-card-list>
        </div>
      </div>
    </div>
    <!-- nearestJob List end -->

    <!-- Nearest Site List BEGIN -->
    <div
      *ngIf="
        ['addClientPhoto', 'addClientComment', 'userPromptInfo'].includes(
          detailType
        )
      "
    >
      <div *ngIf="dupnearestClientList.length === 0" class="my-3">
        <div class="alert alert-primary fw-bold">No Clients Near You</div>
      </div>

      <div *ngIf="dupnearestClientList.length > 0">
        <div *ngIf="newComment || webcamImage">
          <div class="alert alert-primary mb-2">Select Client</div>
        </div>
        <div class="form-floating mb-3" *ngIf="!distanceCalculation">
          <input
            type="text"
            class="form-control"
            id="filterResults"
            placeholder="Filter Clients"
            [(ngModel)]="searchFilter"
            (keyup)="onFilterClientsApply($event)"
          />
          <label for="filterResults">Filter Clients</label>
        </div>
        <div *ngIf="apidataUpdation === true && !offlineMode">
          <div class="alert alert-primary my-3">
            <div class="fw-bold">
              Fetching Data from Server

              <div class="spinner-grow float-end" role="status"></div>
            </div>
          </div>
        </div>

        <div *ngIf="nearestClientList?.length == 0" class="my-3">
          <div class="alert alert-primary fw-bold">
            No Records Match Your Search Criteria
          </div>
        </div>

        <div class="list-fixed-height row">
          <div
            class="gy-2"
            *ngFor="let company of nearestClientList"
            (click)="updateCompany(company)"
          >
            <div class="card p-2">
              <div class="fw-bold">{{ company?.company_name }}</div>
              <div>{{ company?.full_address }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Nearest Site List END -->
  </div>
  <div *ngIf="detailType !== 'updateJob'">
    <div class="bottom-section" id="fixedBtn">
      <div class="mx-2 mb-3">
        <button class="btn btn-dark btn-lg w-100" (click)="goBack()">
          Back
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #commentRef let-commentRef="dialogRef">
  <div class="card dialog-box">
    <div class="card-header">
      Add a Comment
      <span (click)="commentRef.close('close')" class="float-end">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <!--api returns an empty client list-->
    <div *ngIf="!apidataUpdation && !nearestClientList?.length">
      <div class="alert alert-warning">No clients found</div>
      <div class="col">
        <button
          class="btn btn-dark btn-lg w-100"
          (click)="commentRef.close('close')"
        >
          Back
        </button>
      </div>
    </div>

    <div
      *ngIf="apidataUpdation || (!apidataUpdation && nearestClientList?.length)"
    >
      <div class="col">
        <textarea
          class="form-control"
          rows="10"
          maxlength="340"
          [disabled]="
            !apidataUpdation && !nearestClientList?.length ? true : false
          "
          placeholder="Add a Comment..."
          [(ngModel)]="newComment"
        ></textarea>
      </div>

      <div class="row row-cols-2 mt-4">
        <div class="col">
          <button
            class="btn btn-dark btn-lg w-100"
            (click)="commentRef.close('close')"
          >
            Back
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-primary btn-lg w-100"
            [disabled]="!newComment"
            (click)="commentRef.close()"
          >
            Add Comment
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
