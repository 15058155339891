import { Component, OnInit } from '@angular/core';
import { cardDeleteActionIcon } from '../../../../global.variable';
import { AddEditMonitoringCompanyComponent } from '../../../shared/components/add-edit-monitoring-company/add-edit-monitoring-company.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ClientsService } from '../clients/clients.service';

@Component({
  selector: 'gtapp-monitoring-company',
  templateUrl: './monitoring-company.component.html',
  styleUrl: './monitoring-company.component.scss',
})
export class MonitoringCompanyComponent implements OnInit {
  globalFilter: any;
  cardDetails = {
    individualDetails: [
      {
        name: 'company_name',
        cardRowStyle: { 'font-weight': 'bolder' },
      },
    ],
  };
  clientCount: number = 0;
  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  monitoringCompanyList: any = [];
  totalClientCount: number = 0;

  dialogref: any;
  cardActionIcons = cardDeleteActionIcon;
  otherParams: any = {};
  companyDropDownList: any = [];
  constructor(
    private customerService: ClientsService,
    private spinnerService: LoadingSpinnerService,
    private dialogService: ModelDialogueService,

    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.fetchMonitoringCompanyList();
  }
  fetchMonitoringCompanyList() {
    let params: any = {};
    if (this.pageSize) {
      params['rows'] = this.pageSize;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    if (this.globalFilter) {
      params['search_str'] = this.globalFilter;
    }

    this.spinnerService.show();
    this.customerService
      .fetchMonitoringCompanyList(params)
      .subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.clientCount = res['total_size'];
          this.otherParams.paginationData = {
            totalRows: this.clientCount,
            previousRows: this.previous,
            rows: this.pageSize,
            pageNum: this.pageNum,
            totalPages: this.totalPages,
          };
          this.companyDropDownList = [];
          this.totalClientCount = res?.monitoring_company_count || 0;
          this.totalPages = Math.ceil(this.clientCount / this.pageSize);
          this.monitoringCompanyList = res?.data;
        }
        this.spinnerService.hide();
      });
  }

  onCompanyLookup(event: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.companyDropDownList?.length)
    ) {
      this.customerService
        .fetchMonitoringCompanyList({ search_str: event.target.value })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.companyDropDownList = res?.data;
          }
        });
    }
  }

  onChangePagination(event: any) {
    this.previous = event?.paginationData?.previousRows;
    this.pageNum = event?.paginationData?.pageNum;
    this.pageSize = event?.paginationData?.rows;
    this.fetchMonitoringCompanyList();

    this.otherParams.paginationData = { ...event?.paginationData };
  }
  openAddCompanyForm() {
    this.dialogref = this.dialogService.open(
      AddEditMonitoringCompanyComponent,
      {
        data: { updateView: false },
      }
    );
    this.dialogref.afterClosed().subscribe((value: any) => {
      if (value != 'close') {
        this.fetchMonitoringCompanyList();
      }
    });
  }
  openEditCompanyForm(data: any) {
    this.dialogref = this.dialogService.open(
      AddEditMonitoringCompanyComponent,
      {
        data: { updateView: true, companyData: data },
      }
    );
    this.dialogref.afterClosed().subscribe((value: any) => {
      if (value != 'close') {
        this.fetchMonitoringCompanyList();
      }
    });
  }
  deleteCompany(data: any) {
    let dialogMsg = 'WARNING: This action cannot be reversed.';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete Company',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.customerService
          .deleteCompany(data.id, { delete: 1 })
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.fetchMonitoringCompanyList();
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    });
  }
}
