<div *ngIf="pageLoaded">
  <div *ngIf="subscriptions.length && !currentSubscription">
    <div class="alert alert-warning my-2 text-center">Subscription Expired</div>
  </div>
  <ng-container *ngIf="showPlans === true">
    <!-- First Time subscriber header -->
    <ng-container *ngIf="!currentSubscription">
      <ng-container
        *ngIf="!exBeta && firstTimeSubscriber && subscriptions.length === 0"
      >
        <div class="text-center mb-3">
          <h5>Step 6: Select Plan</h5>
        </div>
      </ng-container>

      <!-- EXPIRED  SUBSCRIBERS -->
      <ng-container
        *ngIf="expiredSubscriptions?.length && subscriptions.length > 0"
      >
        <div class="text-center mb-3">
          <h5>Subscribe</h5>
          <div class="mb-3">
            To reactivate Guard Tracker access, please subscribe to a plan.
          </div>
          <div class="mb-3">Change plans later or cancel at any time.</div>
        </div>
      </ng-container>

      <!-- Ex Beta user's header -->
      <ng-container *ngIf="exBeta && subscriptions.length === 0">
        <div class="text-center mb-3">
          <h5>Beta Program Ended</h5>
          <div class="mb-3">
            Thank you for participating in our beta program.
          </div>
          <div class="mb-3">
            To continue your use of Guard Tracker, please subscribe to a plan.
          </div>
          <div class="mb-3">Change plans later or cancel at any time.</div>
        </div>
      </ng-container>
    </ng-container>

    <!-- showing plan list here-->
    <div class="text-center mb-3">
      All pricing is inclusive of GST and in Australian Dollars
    </div>
    <div
      id="pricing-container"
      class="d-flex flex-wrap justify-content-center flex-row gy-3"
    >
      <div
        class="pricing-plan"
        *ngFor="let plan of appPlans"
        [ngClass]="
          plan?.recommended ? 'border border-primary-subtle border-4' : ''
        "
      >
        <div class="h-100 d-flex flex-column">
          <div class="">
            <h4 class="plan-name">{{ plan.name }}</h4>
            <p class="card-text description mb-1">
              Includes {{ plan?.allowed_users }} Users
            </p>
            <hr />
            <div *ngIf="plan?.trial_days && isTrialApplicable" class="mt-1">
              <p class="card-text">
                <span class="price">$0</span> for the first
                {{ plan.trial_days }} days
              </p>
              <p class="card-text mb-0">then</p>
            </div>
            <div
              [ngClass]="!plan?.trial_days || !isTrialApplicable ? 'pt-3' : ''"
              class="mt-auto"
            >
              <p class="mb-0">
                <span class="price mt-2">${{ plan.final_amount }}</span> per
                month
              </p>
              <ng-container *ngIf="plan?.plan_type == 1">
                <p class="mt-1 mb-3">
                  <span class="fw-bold">
                    ${{ getRoundOffValue(plan?.user_cost, false) }}
                  </span>
                  per additional user
                </p>
              </ng-container>
              <ng-container *ngIf="plan?.plan_type == 2">
                <!-- transaction price payment plan-->
                <div class="small mb-0">
                  {{ plan?.allowed_transactions }} transactions included
                </div>
                <div class="small">
                  {{ getRoundOffValue(plan?.transaction_price) }}c per
                  transaction thereafter
                </div>
              </ng-container>
            </div>
            <div class="text-center small" *ngIf="plan?.recommended">
              Save <span class="fw-bold">${{ plan?.amtSavable }} </span> per
              month switching to this plan
            </div>
          </div>
          <div class="card-footer mt-auto">
            <button
              class="btn btn-primary btn-select-plan btn-lg w-100"
              (click)="onPlanClick(plan)"
            >
              Select
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col m-auto w-50" *ngIf="currentSubscription && showPlans">
      <button
        class="btn btn-dark w-100 btn-lg"
        (click)="showPlans = !showPlans; showApplyCouponForm = false"
      >
        Cancel
      </button>
    </div>
  </ng-container>
  <div *ngIf="showPaymentForm === true">
    <gtapp-payment-form
      [selectedPlan]="selectedPlan"
      [currentSubscription]="currentSubscription"
      [totalAddedUsers]="totalAddedUsers"
      (emitData)="cancelPaymentForm()"
    >
    </gtapp-payment-form>
  </div>

  <ng-container *ngIf="showApplyCouponForm === true">
    <div class="p-2 col-lg-7 col-xl-5 m-auto">
      <div class="mb-2">
        <h5
          class="plan-name mb-4"
          [ngStyle]="
            dataAfterCouponApplied ? { color: 'var(--color-success-500)' } : {}
          "
        >
          {{ queuedPlanData?.plan?.name }}
        </h5>
        <div class="mb-0 mt-1">
          <span> ${{ queuedPlanData?.plan?.final_amount }}</span> per month
        </div>

        <ng-container *ngIf="queuedPlanData?.plan?.plan_type == 1">
          <!-- user price payment plan-->
          <div class="mt-2">
            ${{ queuedPlanData?.plan?.user_cost | misc : "convertIntoDollar" }}
            per additional user
          </div>

          <div class="mt-5 mb-2">
            <!-- Display Extra Users and Pricing -->
            <div>
              <div class="mb-3">
                <!-- Headers -->
                <div class="row row-cols-4">
                  <div class="col-6 px-1 fw-bold"></div>
                  <div class="col-1 px-1 fw-bold text-center">Qty</div>
                  <div class="col-3 px-2 fw-bold text-end">Unit Price</div>
                  <div class="col-2 px-1 fw-bold text-end">Total</div>
                </div>

                <!-- Base Plan -->
                <div class="row row-cols-4 my-2">
                  <div class="col-6">
                    {{ queuedPlanData?.plan?.name }} ({{
                      queuedPlanData?.plan?.allowed_users
                    }}
                    Users)
                  </div>
                  <div class="col-1 px-1 text-center">1</div>
                  <div class="col-3 px-1 text-end">
                    ${{ (queuedPlanData?.plan?.final_amount).toFixed(2) }}
                  </div>
                  <div class="col-2 px-1 text-end">
                    ${{ (queuedPlanData?.plan?.final_amount).toFixed(2) }}
                  </div>
                </div>

                <!-- Additional Guards -->
                <ng-container
                  *ngIf="allottedSlots > queuedPlanData?.plan?.allowed_users"
                >
                  <div class="row row-cols-4">
                    <div class="col-6">Additional User(s)</div>
                    <div class="col-1 px-1 text-center">
                      {{ allottedSlots - queuedPlanData?.plan?.allowed_users }}
                    </div>
                    <div class="col-3 px-1 text-end">
                      ${{ (queuedPlanData?.plan?.user_cost).toFixed(2) }}
                    </div>
                    <div class="col-2 px-1 text-end">
                      ${{ getExtraUserCost().toFixed(2) }}
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="isCouponApplied">
        <!-- Display the applied coupon with a remove option -->
        <div class="d-flex flex-grow-1 me-2 mb-3">
          <span
            >Coupon {{ couponCode }}
            <span class="fw-bold text-success">(Applied)</span></span
          >
          <label class="text-danger" (click)="removeAppliedCoupon()">
            Remove
          </label>
        </div>
      </ng-container>
      <ng-container *ngIf="showCouponInput && !isCouponApplied">
        <div class="d-flex">
          <input
            class="form-control flex-grow-1 me-2"
            type="text"
            [(ngModel)]="couponCode"
            id="couponCode"
            placeholder="Coupon code"
            (input)="forceUppercaseConditionally($event)"
          />
          <button
            class="apply-button mt-0"
            class="btn btn-primary btn-lg"
            (click)="onApplyCouponCode()"
            [disabled]="!couponCode"
          >
            Apply
          </button>
        </div>
        <div *ngIf="errorCouponCode === true" class="text-danger mb-3">
          Invalid coupon
        </div>
      </ng-container>
      <div class="text-end" *ngIf="!showCouponInput && !isSubscriberLocked">
        <button
          (click)="showCouponInput = !showCouponInput"
          class="btn btn-secondary btn-sm mb-2"
        >
          Apply Coupon
        </button>
      </div>

      <ng-container *ngIf="queuedPlanData?.plan?.plan_type == 1">
        <div class="mt-2">
          <div class="d-flex justify-content-end mb-2">
            <span class="me-3">GST</span>
            <span class="amount-to-pay">${{ getGSTAmount() }}</span>
          </div>

          <div class="d-flex justify-content-end fw-bold">
            <span class="me-3">Total with GST</span>
            <span class="amount-to-pay"
              >${{ getTotalAmount().toFixed(2) }}</span
            >
          </div>
        </div>
      </ng-container>
      <div class="small-font text-end me-3 mt-2">
        Change plans later or cancel at any time
      </div>
      <div class="mt-2 mb-3">
        <div class="alert alert-warning">
          This plan will take effect on your next billing cycle [{{
            getBillingDate(currentSubscription) | date : "d MMMM y"
          }}]
        </div>
      </div>
      <div class="row row-cols-2 gy-2">
        <div class="col">
          <button
            class="btn btn-dark w-100 btn-lg"
            (click)="
              queuedPlanData = null;
              showApplyCouponForm = false;
              removeAppliedCoupon()
            "
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-primary w-100 btn-lg"
            (click)="queueSubscription()"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!showPlans && !showPaymentForm && !showApplyCouponForm">
    <div *ngIf="currentSubscription && !showApplyCouponForm && !addUser">
      <div class="row row-cols-lg-2">
        <div class="col-12 col-lg-10">
          <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-xl-2">
            <div class="col-12 col-lg-5 col-xl-5">
              <dl>
                <dt>Your Plan Details</dt>
                <dd>
                  {{ currentSubscription?.plan?.name }}
                  <br />
                  <span>
                    ${{
                      currentSubscription?.app_coupon?.payable_amount
                        ? currentSubscription?.app_coupon?.payable_amount
                        : currentSubscription?.plan?.final_amount
                    }}</span
                  ><span> per month</span>
                  <ng-container
                    *ngIf="currentSubscription?.plan?.plan_type == 1"
                  >
                    <!-- user price payment plan-->
                    <br />
                    <span>
                      ${{
                        currentSubscription?.plan?.user_cost
                          | misc : "convertIntoDollar"
                      }}
                      per additional user per month</span
                    >
                    <br />
                  </ng-container>
                </dd>
              </dl>

              <ng-container *ngIf="currentSubscription?.plan?.plan_type == 1">
                <dl
                  id="excess-usage"
                  *ngIf="
                    currentSubscription?.allotted_slots >
                    currentSubscription?.plan?.allowed_users
                  "
                >
                  <dt>Additional Users</dt>
                  <dd>
                    {{
                      currentSubscription?.allotted_slots -
                        currentSubscription?.plan?.allowed_users
                    }}
                  </dd>
                </dl>
                <dl>
                  <dt>Total Users</dt>
                  <dd>
                    <span>{{ currentSubscription?.allotted_slots }}</span>
                  </dd>
                </dl>
              </ng-container>
            </div>

            <div class="col-12 col-lg-7 col-xl-7">
              <div *ngIf="!queuedSelectedPlanData?.plan?.id">
                <dl>
                  <dt>
                    {{
                      currentSubscription?.recurring_plan
                        ? "Renewal Details"
                        : "Subscription Ends"
                    }}
                    <div
                      class="float-end"
                      *ngIf="
                        !currentSubscription?.recurring_plan &&
                        paymentCards?.length
                      "
                    >
                      <button
                        (click)="
                          changeSubscriptionRecursion(currentSubscription)
                        "
                        class="btn btn-sm btn-success"
                      >
                        Auto Renew
                      </button>
                    </div>
                  </dt>
                  <dd
                    [ngClass]="
                      currentSubscription?.recurring_plan ? '' : 'text-danger'
                    "
                  >
                    <span *ngIf="currentSubscription?.recurring_plan">
                      <span class="fw-bold">
                        ${{
                          (
                            (currentSubscription?.app_coupon?.payable_amount
                              ? currentSubscription?.app_coupon?.payable_amount
                              : currentSubscription?.plan?.final_amount) +
                            (totalAddedUsers >
                            currentSubscription?.plan?.allowed_users
                              ? (totalAddedUsers -
                                  currentSubscription?.plan?.allowed_users) *
                                currentSubscription?.plan?.user_cost
                              : 0)
                          ).toFixed(2)
                        }}</span
                      >
                      will be deducted on
                    </span>
                    {{
                      getBillingDate(currentSubscription) | date : "d MMM yyy"
                    }}

                    <ng-container *ngIf="currentSubscription?.recurring_plan">
                      <ng-template
                        *ngTemplateOutlet="
                          pricebreakdownTemplate;
                          context: {
                            subscriptionPlan: currentSubscription,
                            allottedSlots: totalAddedUsers
                          }
                        "
                      ></ng-template>
                    </ng-container>
                  </dd>
                </dl>
              </div>

              <div *ngIf="queuedSelectedPlanData?.plan?.id">
                <dl>
                  <dt>Your plan will change on</dt>
                  <dd>
                    <span class="fw-bold">
                      {{
                        getBillingDate(currentSubscription) | date : "d MMM yyy"
                      }}</span
                    >
                    <br />
                  </dd>

                  <dt>Plan Details</dt>
                  <dd>
                    {{ queuedSelectedPlanData?.plan?.name }}
                    <br />
                    <span class="fw-bold">
                      ${{
                        queuedSelectedPlanData?.app_coupon?.payable_amount
                          ? queuedSelectedPlanData?.app_coupon?.payable_amount
                          : queuedSelectedPlanData?.plan?.final_amount
                      }}</span
                    ><span class="small"> /month</span>
                    <ng-container
                      *ngIf="queuedSelectedPlanData?.plan?.plan_type == 1"
                    >
                      <!-- user price payment plan-->
                      <br />
                      <span class="small">
                        {{ queuedSelectedPlanData?.plan?.allowed_users }}
                        users included</span
                      >
                      <br />
                      <span class="small">
                        {{
                          queuedSelectedPlanData?.plan?.user_cost
                            | misc : "convertIntoDollar"
                        }}
                        $ per user thereafter</span
                      >
                    </ng-container>
                  </dd>
                </dl>

                <ng-container
                  *ngIf="queuedSelectedPlanData?.plan?.plan_type == 1"
                >
                  <dl>
                    <span class="fw-bold">
                      ${{
                        (
                          (queuedSelectedPlanData?.app_coupon?.payable_amount
                            ? queuedSelectedPlanData?.app_coupon?.payable_amount
                            : queuedSelectedPlanData?.plan?.final_amount) +
                          (totalAddedUsers >
                          queuedSelectedPlanData?.plan?.allowed_users
                            ? (totalAddedUsers -
                                queuedSelectedPlanData?.plan?.allowed_users) *
                              queuedSelectedPlanData?.plan?.user_cost
                            : 0)
                        ).toFixed(2)
                      }}</span
                    >
                    will be deducted on

                    {{
                      getBillingDate(currentSubscription) | date : "d MMM yyy"
                    }}
                    <ng-template
                      *ngTemplateOutlet="
                        pricebreakdownTemplate;
                        context: {
                          subscriptionPlan: queuedSelectedPlanData,
                          allottedSlots: totalAddedUsers
                        }
                      "
                    ></ng-template>
                  </dl>
                </ng-container>
                <br />

                <button
                  *ngIf="queuedSubscriptions?.length"
                  class="btn btn-dark btn-lg"
                  (click)="deleteQueuedPlan(queuedSelectedPlanData)"
                >
                  Cancel Plan Change
                </button>
              </div>
              <span
                *ngIf="
                  paymentCards?.length == 0 &&
                  currentSubscription?.recurring_plan
                "
                class="fw-bold text-danger"
              >
                No credit card has been saved, please add one before
                {{ getBillingDate(currentSubscription) | date : "d MMM yyy" }}
                to ensure your service is not distrupted
              </span>
            </div>
          </div>
        </div>

        <div
          class="col-lg-2 ps-2"
          *ngIf="
            !queuedSubscriptions?.length &&
            currentSubscription?.is_active &&
            paymentCards?.length &&
            !isSubscriberLocked
          "
        >
          <div class="row row-cols-1 gy-2">
            <div class="col" *ngIf="currentSubscription?.recurring_plan">
              <button
                class="btn btn-dark w-100 btn-lg"
                (click)="changeSubscriptionRecursion(currentSubscription)"
              >
                Cancel Subscription
              </button>
            </div>
            <div class="col">
              <button
                class="btn btn-primary w-100 btn-lg"
                (click)="showPlans = !showPlans; showApplyCouponForm = false"
              >
                Change Plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="subscriptions?.length && !showPlans && !addUser">
      <div class="mt-2">
        <ul class="nav nav-tabs border-0">
          <li class="nav-item" (click)="changeTab(tabNames.tab1)">
            <a
              class="nav-link"
              [ngClass]="selectedTab === tabNames.tab1 ? 'active' : ''"
              >{{ tabNames.tab1 }}</a
            >
          </li>
          <li class="nav-item" (click)="changeTab(tabNames.tab2)">
            <a
              class="nav-link"
              [ngClass]="selectedTab === tabNames.tab2 ? 'active' : ''"
              >{{ tabNames.tab2 }}</a
            >
          </li>
        </ul>

        <div class="tab-content mt-2">
          <!-- Tab Pane for Tab 1 -->
          <div
            class="tab-pane"
            [ngClass]="selectedTab === tabNames.tab1 ? 'active' : ''"
          >
            <gtapp-saved-cards (selectCard)="changeCardStatus($event)">
            </gtapp-saved-cards>
          </div>

          <!-- Tab Pane for Tab 2 -->
          <div
            class="tab-pane"
            [ngClass]="selectedTab === tabNames.tab2 ? 'active' : ''"
          >
            <gtapp-card-list
              [mobileCardBackendData]="subscriptionInvoices"
              [mobileCardDetails]="invoiceDetails"
              [mobileActionIcons]="invoiceIcons"
              (actionClick)="downloadInvoice($event)"
            ></gtapp-card-list>
          </div>
        </div>
      </div>
    </ng-container>

    <div
      class="text-end mb-5"
      *ngIf="
        (!showPlans &&
          showPaymentForm === false &&
          subscriptions?.length > 0) ||
        (currentSubscription &&
          currentSubscription?.recurring_plan === false &&
          !showPlans &&
          addUser === false)
      "
    >
      <button
        class="btn btn-dark btn-lg"
        (click)="deleteSubscriberTemplate(deleteAccountTemp)"
      >
        Close Account
      </button>
    </div>
  </ng-container>
</div>
<ng-template #deleteAccountTemp let-delRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      WARNING

      <span class="float-end" (click)="delRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <p class="fw-bold">THIS ACTION CANNOT BE UNDONE!</p>
      <p class="fw-bold text-danger">
        BY CLOSING YOUR ACCOUNT YOU AGREE TO HAVE ALL YOUR DATA DELETED.
      </p>
      <ng-container *ngIf="deleteAccStep === 1">
        <div class="text-center">
          To confirm deletion, type {{ randomNumber }} in the field:
        </div>
        <div class="mt-2">
          <input
            class="form-control"
            tabindex="1"
            autofocus
            type="tel"
            placeholder="{{ randomNumber }}"
            (input)="checkValidation($event)"
          />
        </div>
      </ng-container>
      <ng-container *ngIf="deleteAccStep === 2">
        <div class="text-center">Reason for account closure</div>
        <select class="form-select" (change)="selectDeleteReason($event)">
          <option value="" disabled selected hidden>Reason</option>
          <option
            style="display: flex"
            *ngFor="let reason of feedbackOptions"
            [value]="reason.option"
          >
            {{ reason.option }}
          </option>
        </select>

        <div *ngIf="showReasonText" class="mt-6">
          <textarea
            autofocus
            rows="4"
            placeholder="Your valuable feedback"
            autocomplete="off"
            [(ngModel)]="cancelReason"
            style="resize: none"
          ></textarea>
        </div>
      </ng-container>

      <div class="row row-cols-2 mt-2">
        <div
          class="col"
          (click)="delRef.close(); cancelReason = null; showReasonText = false"
        >
          <button
            tabindex="-1"
            id="cancelBtn"
            class="btn btn-dark btn-lg w-100"
          >
            Cancel
          </button>
        </div>
        <div class="col" (click)="deleteSubscriber()">
          <button
            class="btn btn-danger btn-lg w-100"
            [disabled]="!cancelReason"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  let-subscriptionPlan="subscriptionPlan"
  let-allottedSlots="allottedSlots"
  #pricebreakdownTemplate
>
  <!--price breakdown template-->
  <div class="p-2 ps-0 mt-2">
    <!-- Headers -->
    <div class="row row-cols-4 mb-3">
      <div class="col-4"></div>
      <div class="col-2 fw-bold text-center">Qty</div>
      <div class="col-3 fw-bold text-end">Unit Price</div>
      <div class="col-3 fw-bold text-end">Sub Total</div>
    </div>

    <!-- Base Plan -->
    <div class="row row-cols-4 mb-3">
      <div class="col-4">
        {{ subscriptionPlan?.plan?.name }}
        <div class="small">
          Includes {{ subscriptionPlan?.plan?.allowed_users }} Users
        </div>
      </div>
      <div class="col-2 text-center">1</div>
      <div class="col-3 text-end">
        ${{ (subscriptionPlan?.plan?.final_amount).toFixed(2) }}
      </div>
      <div class="col-3 text-end">
        ${{ (subscriptionPlan?.plan?.final_amount).toFixed(2) }}
      </div>
    </div>

    <!-- Additional Guards -->
    <div
      class="row row-cols-4 mb-3"
      *ngIf="allottedSlots - subscriptionPlan?.plan?.allowed_users > 0"
    >
      <div class="col-4">Additional User</div>
      <div class="col-2 text-center">
        {{ allottedSlots - subscriptionPlan?.plan?.allowed_users }}
      </div>
      <div class="col-3 text-end">
        ${{ subscriptionPlan?.plan?.user_cost?.toFixed(2) }}
      </div>
      <div class="col-3 text-end">
        ${{
          (
            subscriptionPlan?.plan?.user_cost *
            (allottedSlots - subscriptionPlan?.plan?.allowed_users)
          ).toFixed(2)
        }}
      </div>
    </div>

    <div class="fw-bold mt-2 text-end">{{ allottedSlots }} Users Total</div>

    <div class="fw-bold mt-2 text-end">
      ${{
        (
          (subscriptionPlan?.app_coupon?.payable_amount
            ? subscriptionPlan?.app_coupon?.payable_amount
            : subscriptionPlan?.plan?.final_amount) +
          (totalAddedUsers > subscriptionPlan?.plan?.allowed_users
            ? (totalAddedUsers - subscriptionPlan?.plan?.allowed_users) *
              subscriptionPlan?.plan?.user_cost
            : 0)
        ).toFixed(2)
      }}
      per month
    </div>
  </div>
</ng-template>
