<!-- Data Refreshing Alert -->
<ng-container *ngIf="apidataUpdation === true && !offlineMode">
  <div class="alert alert-primary mb-3">
    <div class="fw-bold">
      Fetching Data from Server

      <div class="spinner-grow float-end" role="status"></div>
    </div>
  </div>
</ng-container>

<!-- Job Data Not Found Alert -->
<ng-container *ngIf="!detailData && offlineMode">
  <div class="alert alert-warning mb-2">
    <div>Job Data Not Found</div>
  </div>
</ng-container>
<div *ngIf="detailData">
  <div
    class="status-card"
    *ngIf="!largeView && selectedJobStatus?.status_identity?.value === 4"
  >
    <div class="text-center">
      <div class="my-1 onsite-time">Onsite: {{ onsiteTimer }}</div>
    </div>
  </div>

  <!-- Cancellation Reason -->
  <div class="px-1">
    <div
      class="my-2 alert alert-danger alert-dismissible fade show"
      role="alert"
      *ngIf="
        cancelReasonData && detailData?.status?.status_identity?.value === 2
      "
    >
      <div>
        <div>
          CANCELLED:
          <span class="fw-bold">
            {{ detailData?.modified_date | date : "d MMM yyyy H:mm" }}
          </span>
        </div>
      </div>
      <div *ngIf="cancelReasonData?.reason">
        <div>
          CANCEL REASON:

          <span class="fw-bold">{{ cancelReasonData?.reason }}</span>
        </div>
      </div>
    </div>
  </div>

  <div>
    <!-- <div
      *ngIf="!largeView"
      class="job-status"
      [style]="selectedJobStatus?.additional_info?.style?.snubbed"
    ></div> -->
    <!-- Job Details and Comments -->
    <div
      [style.padding-bottom]="
        applyMaxHeight ? bottomSectionFixedHeight + ' !important' : null
      "
    >
      <div class="row row-cols-1 row-cols-lg-3 gt-card">
        <div class="col">
          <dl *ngIf="detailData?.showEta">
            <dt
              (click)="
                offlineMode
                  ? showOfflineMessage()
                  : detailData?.status?.status_identity?.value === 8
                  ? openTemplate(inTransitTemplateRef)
                  : ''
              "
            >
              ETA
              <i
                class="fa-solid fa-pen-to-square"
                *ngIf="
                  (isAdmin || isDispatchUser) &&
                  !offlineMode &&
                  detailData?.status?.status_identity?.value === 8
                "
              ></i>
            </dt>
            <dd>{{ detailData?.eta }} minutes</dd>
          </dl>
          <dl>
            <dt>Job Type</dt>
            <dd>
              {{ detailData?.service_type?.name }}
              <span *ngIf="detailData?.response_type?.name"
                >&nbsp;-&nbsp;
                <span class="fw-bold">{{
                  detailData?.response_type?.name
                }}</span>
              </span>
            </dd>
          </dl>
          <ng-container *ngIf="detailData?.roster_scheduler?.repeat_type">
            <div class="row row-cols-2">
              <div class="col">
                <dl>
                  <!-- Date/Duration Section -->
                  <dt>Start Date</dt>
                  <dd>
                    {{
                      detailData?.schedule_event_date
                        ? (detailData?.schedule_event_date | date : "d MMM y")
                        : (detailData?.roster_scheduler?.start_day
                          | date : "d MMM y")
                    }}
                  </dd>
                </dl>
              </div>
              <div class="col">
                <dl>
                  <dt
                    [ngClass]="
                      isAdmin || isDispatchUser ? 'cursor-pointer' : ''
                    "
                    (click)="
                      offlineMode
                        ? showOfflineMessage()
                        : (isAdmin || isDispatchUser) &&
                          ![1, 2, 3].includes(
                            detailData?.status?.status_identity?.value
                          ) &&
                          canScheduleBeUpdated
                        ? openScheduleTemplate(changeScheduleTemplateRef)
                        : ''
                    "
                  >
                    Shift [Start - End]
                    <i
                      class="fa-solid fa-pen-to-square ms-2"
                      *ngIf="
                        canScheduleBeUpdated &&
                        (isAdmin || isDispatchUser) &&
                        !offlineMode &&
                        ![1, 2, 3].includes(
                          detailData?.status?.status_identity?.value
                        )
                      "
                    ></i>
                  </dt>
                  <dd>
                    {{ detailData?.roster_scheduler?.start_time }} -
                    {{ detailData?.roster_scheduler?.end_time }}
                  </dd>
                </dl>
              </div>
              <div class="col">
                <dl>
                  <!-- Job Duration Section -->
                  <dt>Job Duration</dt>
                  <dd>{{ scheduleDuration }} Hours</dd>
                </dl>
              </div>
              <div class="col">
                <dl
                  *ngIf="
                    showWelfareItem &&
                    (isAdmin ||
                      isDispatchUser ||
                      detailData?.interval_check > 0)
                  "
                >
                  <!-- Welfare Checks Section -->
                  <dt
                    [ngClass]="
                      isAdmin || isDispatchUser ? 'cursor-pointer' : ''
                    "
                    (click)="
                      offlineMode
                        ? showOfflineMessage()
                        : (isAdmin || isDispatchUser) &&
                          ![1, 2, 3].includes(
                            detailData?.status?.status_identity?.value
                          )
                        ? openIntervalCheckTemplate(welfareIntervalTemplate)
                        : ''
                    "
                  >
                    Welfare Checks
                    <i
                      class="fa-solid fa-pen-to-square"
                      *ngIf="
                        (isAdmin || isDispatchUser) &&
                        !offlineMode &&
                        ![1, 2, 3].includes(
                          detailData?.status?.status_identity?.value
                        )
                      "
                    ></i>
                  </dt>
                  <dd>{{ detailData?.interval_check }} mins</dd>
                </dl>
              </div>
            </div>
          </ng-container>
          <dl>
            <dt (click)="offlineMode ? showOfflineMessage() : redirect('site')">
              Site <i class="fa-solid fa-link"></i>
            </dt>
            <dd>
              {{ detailData?.sites[0]?.company_name }}
              <br />
              <span class="small">{{ detailData?.company?.company_name }}</span>
            </dd>
          </dl>

          <dl>
            <dt>Address</dt>
            <dd>
              <div class="d-flex">
                <div class="flex-grow-1 me-3">
                  {{ detailData?.sites[0]?.address?.full_address }}
                </div>
                <a
                  [href]="
                    'https://www.google.com/maps/search/?api=1&query=' +
                    detailData?.sites[0]?.address?.latitude +
                    ',' +
                    detailData?.sites[0]?.address?.longitude
                  "
                  target="_blank"
                >
                  <span class="me-3">
                    <i class="fa-solid fa-route"></i>
                  </span>
                </a>
              </div>
            </dd>
          </dl>
          <dl *ngIf="detailData?.sites[0]?.site_information">
            <!--site information-->

            <dt>Site Information</dt>
            <dd class="gt-multi-line">
              {{
                detailData?.sites[0]?.site_information
                  ? detailData?.sites[0]?.site_information
                  : "No details provided"
              }}
            </dd>
          </dl>
          <dl class="mt-3" *ngIf="detailData?.sites[0]?.site_files?.length">
            <dt>Site Files</dt>
            <dd class="row p-1">
              <div
                *ngFor="
                  let file of detailData?.sites[0]?.site_files;
                  let i = index
                "
                class="col-12 mb-2 d-flex align-items-center cursor-pointer"
              >
                <span (click)="onOpenFile(file)">
                  <span class="badge bg-primary me-2">{{
                    file?.extension
                  }}</span>
                  <span> {{ file?.description }}</span>
                </span>
              </div>
            </dd>
          </dl>
          <dl class="mt-3" *ngIf="detailData?.sites[0]?.site_keys?.length">
            <dt>Site Files</dt>

            <dd class="row p-1">
              <div
                *ngFor="let siteKey of detailData?.sites[0]?.site_keys"
                class="col-12 mb-2 d-flex align-items-center cursor-pointer"
              >
                <span>
                  {{ siteKey }}
                </span>
              </div>
            </dd>
          </dl>

          <dl>
            <ng-container
              *ngIf="
                (detailData?.monitoring_company?.id ||
                  isAdmin === true ||
                  isDispatchUser === true) &&
                detailData?.service_type?.type_identity === 0
              "
            >
              <dt
                (click)="
                  offlineMode
                    ? showOfflineMessage()
                    : !detailData?.monitoring_company?.id &&
                      ![1, 2, 3].includes(
                        detailData?.status?.status_identity?.value
                      )
                    ? modifyMonitoringCompany(modifyMCTemplate)
                    : ''
                "
              >
                Monitoring Company
                <span
                  *ngIf="
                    !detailData?.monitoring_company?.id &&
                    !offlineMode &&
                    ![1, 2, 3].includes(
                      detailData?.status?.status_identity?.value
                    ) &&
                    (isAdmin || isDispatchUser)
                  "
                >
                  <i class="fa-solid fa-arrow-up-right-from-square"></i>
                </span>
              </dt>
              <dd>
                {{
                  detailData?.monitoring_company?.company_name
                    ? detailData?.monitoring_company?.company_name
                    : "N/A"
                }}
              </dd>
            </ng-container>
          </dl>
        </div>
        <div class="col">
          <dl>
            <dt>Job ID</dt>
            <dd>{{ detailData?.job_key }}</dd>
          </dl>
          <dl
            *ngIf="
              detailData?.service_type?.type_identity === 0 &&
              (isAdmin || isDispatchUser || detailData?.ext_job_id)
            "
          >
            <dt
              (click)="
                offlineMode
                  ? showOfflineMessage()
                  : (isAdmin || isDispatchUser) &&
                    ![1, 2, 3].includes(
                      detailData?.status?.status_identity?.value
                    )
                  ? modifyExternalJobId(modifyExternalJobIdTemplate)
                  : ''
              "
            >
              External Job ID
              <span
                *ngIf="
                  (isAdmin || isDispatchUser) &&
                  !offlineMode &&
                  ![1, 2, 3].includes(
                    detailData?.status?.status_identity?.value
                  )
                "
              >
                <i class="fa-solid fa-pen-to-square"></i>
              </span>
            </dt>
            <dd>
              {{ detailData?.ext_job_id ? detailData?.ext_job_id : "N/A" }}
            </dd>
          </dl>
          <dl
            (click)="
              offlineMode
                ? showOfflineMessage()
                : modifySiteContactTemplate(modifySiteContactRef)
            "
          >
            <dt>
              Onsite Contacts

              <span *ngIf="!offlineMode">
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
              </span>
            </dt>

            <dd>
              <div class="d-flex">
                <div class="flex-grow-1 d-flex align-items-center">
                  {{ detailData?.location_contacts[0]?.full_name }}
                </div>
                <div *ngIf="detailData?.location_contacts?.length > 1">
                  <button class="btn btn-outline-secondary btn-sm">
                    + {{ detailData?.location_contacts?.length - 1 }} more
                  </button>
                </div>
              </div>
              <div
                *ngIf="!detailData?.location_contacts?.length"
                style="color: var(--color-danger-500)"
              >
                No Contacts Added
              </div>
            </dd>
          </dl>
          <dl
            *ngIf="isAdmin || isDispatchUser"
            (click)="
              !offlineMode
                ? modifyAssignees(modifyAssigneeRef)
                : showOfflineMessage()
            "
          >
            <dt [ngClass]="isAdmin || isDispatchUser ? 'cursor-pointer' : ''">
              {{
                detailData?.assignee?.length > 1
                  ? "Assigned Guards"
                  : "Assigned Guard"
              }}
              <span *ngIf="!offlineMode">
                <i class="fa-solid fa-up-right-from-square"></i>
              </span>
            </dt>

            <dd>
              <div class="d-flex">
                <div class="flex-grow-1 d-flex align-items-center">
                  {{ detailData?.assignee[0]?.full_name }}
                </div>
                <div *ngIf="detailData?.assignee?.length > 1">
                  <button class="btn btn-outline-secondary btn-sm">
                    + {{ detailData?.assignee?.length - 1 }} more
                  </button>
                </div>
              </div>
              <div
                *ngIf="!detailData?.assignee?.length"
                style="color: var(--color-danger-500)"
              >
                Not Assigned
              </div>
            </dd>
          </dl>
          <dl>
            <!--additionala information-->

            <dt
              (click)="
                ![1, 2, 3].includes(detailData?.status?.status_identity?.value)
                  ? offlineMode
                    ? showOfflineMessage()
                    : modifyRequestDetails(requestRef)
                  : ''
              "
            >
              Additional Information
              <span
                *ngIf="
                  (isAdmin || isDispatchUser) &&
                  !offlineMode &&
                  ![1, 2, 3].includes(
                    detailData?.status?.status_identity?.value
                  )
                "
              >
                <i class="fa-solid fa-pen-to-square"></i>
              </span>
            </dt>
            <div class="gt-multi-line">
              {{
                detailData?.request_details
                  ? detailData?.request_details
                  : "No details provided"
              }}
            </div>
          </dl>
        </div>

        <div *ngIf="largeView" class="col">
          <div
            *ngIf="detailData?.status?.status_identity?.value !== 1"
            class="text-center p-2 fw-bold"
            [style]="selectedJobStatus?.additional_info?.style?.snubbed"
          >
            {{
              selectedJobStatus?.name ? selectedJobStatus?.name : "NOT STARTED"
            }}
          </div>
          <div
            *ngIf="detailData?.status?.status_identity?.value === 1"
            class="text-center p-2 fw-bold"
            [ngStyle]="
              detailData?.can_reopen
                ? selectedJobStatus?.additional_info?.style?.snubbed
                : {
                    color: 'var(--bs-dark)',
                    background: 'var(--bs-dark)'
                  }
            "
          >
            {{ detailData?.can_reopen ? "JOB COMPLETED" : "JOB CLOSED" }}
          </div>
          <ng-container
            *ngIf="
              detailData?.status?.status_identity?.value == 1 &&
              detailData?.can_reopen &&
              !offlineMode &&
              lastStatus?.status?.id
            "
          >
            <button
              class="btn btn-danger btn-lg w-100 text-light"
              (click)="moveJobBack()"
            >
              Reopen Job
            </button>
          </ng-container>
          <div
            class="row row-cols-2 g-1 pt-2"
            *ngIf="
              scheduleCheck &&
              !offlineMode &&
              detailData?.status?.status_identity?.value !== 1
            "
          >
            <div class="col">
              <button
                *ngIf="detailData?.service_type?.enable_comments === true"
                class="btn btn-primary btn-lg w-100 rounded-0"
                (click)="openAddCommentTemplate(commentRef)"
              >
                + Add Comment
              </button>
            </div>

            <div class="col" (click)="toggleDropdown()">
              <button
                class="btn btn-primary w-100 btm-btn-height rounded-0 d-flex align-items-center justify-content-center"
                (blur)="toggleDropdown()"
              >
                Change Status
                <div class="ms-4">
                  @if(statusDropdownOpen){
                  <span>
                    <i class="fas fa-chevron-up"></i>
                  </span>
                  }@else {
                  <span>
                    <i class="fas fa-chevron-down"></i>
                  </span>
                  }
                </div>
              </button>
              <div class="col dropdown">
                <div class="dropdown-content-down" *ngIf="statusDropdownOpen">
                  <a
                    *ngFor="let item of statusList"
                    (click)="
                      changeJobStatus(
                        item,
                        jobCancelTemplate,
                        onsiteTimeTempalteRef,
                        inTransitTemplateRef
                      )
                    "
                    >{{ item?.status?.name }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Comments -->

        <ng-container *ngIf="!largeView">
          <div
            class="d-flex flex-row-reverse"
            *ngIf="jobCommentsFiles?.length > 1"
          >
            <dl>
              <dt (click)="changeSortOrder = !changeSortOrder">
                <button class="btn btn-sm btn-dark">
                  Sort
                  <span>
                    <i class="fa-solid fa-sort"></i>
                  </span>
                </button>
              </dt>
            </dl>
          </div>
          <div
            *ngFor="
              let msg of changeSortOrder
                ? jobCommentsFiles
                : jobCommentsFiles.slice().reverse();
              let i = index
            "
          >
            <dt>
              <!--update details section-->

              <span *ngIf="msg?.file">
                Photo
                {{ msg?.event_action === 19 ? "[Device Library]" : "[Camera]" }}
                |
              </span>
              {{ msg?.updated_at | date : "d MMM y HH:mm" }} |
              {{
                subscriberUserId == msg?.subs_user_id ? "You" : msg?.user_name
              }}
              <span
                *ngIf="
                  msg?.history_changes?.length &&
                  (subscriberUserId == msg?.subs_user_id ||
                    isAdmin ||
                    isDispatchUser)
                "
              >
                | Edited [{{
                  (msg?.history_changes | misc : "lastCommentEditedDateTime")
                    ?.updated_at | date : "d MMM y HH:mm"
                }}]</span
              >
              <span
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                class="float-end"
                *ngIf="msg?.cachedData"
                title="This update is not yet added to the server."
              >
                <i class="fa-solid fa-triangle-exclamation text-warning"></i>
              </span>

              <span
                class="float-end me-2"
                (click)="
                  subscriberUserId == msg?.subs_user_id &&
                  !offlineMode &&
                  [7, 8, 19].includes(msg?.event_action) &&
                  ![1, 2, 3].includes(
                    detailData?.status?.status_identity?.value
                  )
                    ? editComment(msg, commentRef)
                    : ''
                "
                *ngIf="
                  subscriberUserId == msg?.subs_user_id &&
                  ![1, 2, 3].includes(
                    detailData?.status?.status_identity?.value
                  ) &&
                  !offlineMode &&
                  [7, 8, 19].includes(msg?.event_action)
                "
              >
                <i class="fa-solid fa-pen-to-square"></i>
              </span>
            </dt>

            <dd class="mb-4">
              <!-- comment / photo -->
              <div *ngIf="msg?.event_action === 20">CHECKED IN</div>
              <div>
                <span class="gt-multi-line">
                  {{ msg?.event_action === 1 ? "STATUS: " : "" }}
                  {{
                    msg?.detail === "null" || msg?.detail === "undefined"
                      ? ""
                      : msg?.detail
                  }}
                </span>
                <span *ngIf="msg?.event_action === 0" class="small-font"
                  >[Checkpoint Scan]</span
                >
                <span *ngIf="msg?.event_action === 6" class="small-font"
                  >[QR Scan]</span
                >
              </div>
              <ng-container *ngIf="msg?.file">
                <ng-container *ngIf="msg?.cachedData">
                  <img class="img-fluid" [src]="getImgSrc(msg?.file)"
                /></ng-container>
                <ng-container *ngIf="!msg?.cachedData">
                  <img
                    class="img-fluid"
                    (click)="onOpenImage($event, msg)"
                    src="{{ msg?.file }}"
                    height="120"
                /></ng-container>
              </ng-container>
            </dd>
          </div>
          <ng-container *ngIf="offlineMode">
            <!--offline data saved in the indexed db-->
            <div *ngFor="let msg of offlineSavedData">
              <dt>
                <!--update details section-->

                {{ msg?.updated_at * 1000 | date : "d MMM y HH:mm" }} |
                <span class="text-danger">Waiting for connection</span>
              </dt>

              <dd class="mb-4">
                <!-- comment / photo -->

                <div>
                  <span class="gt-multi-line">
                    {{
                      msg?.comment === "null" || msg?.comment === "undefined"
                        ? ""
                        : msg?.comment
                    }}
                  </span>
                  <span *ngIf="msg?.event_action === 0" class="small-font"
                    >[Checkpoint Scan]</span
                  >
                  <span *ngIf="msg?.event_action === 6" class="small-font"
                    >[QR Scan]</span
                  >
                </div>
                <img
                  class="zoomImage"
                  *ngIf="msg?.file"
                  [src]="getImgSrc(msg?.file)"
                  height="120"
                  width="120"
                  alt="Unable to preview photo while offline"
                />
              </dd>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div
        id="bottom-section-fixed"
        class="bottom-section"
        *ngIf="scheduleCheck && !largeView"
      >
        <div
          class="p-2 my-0"
          *ngIf="![2, 3].includes(detailData?.status?.status_identity?.value)"
        >
          <div
            *ngIf="detailData?.status?.status_identity?.value !== 1"
            class="text-center p-2 fw-bold"
            [style]="selectedJobStatus?.additional_info?.style?.snubbed"
          >
            {{
              selectedJobStatus?.name ? selectedJobStatus?.name : "NOT STARTED"
            }}
          </div>
          <div
            *ngIf="detailData?.status?.status_identity?.value === 1"
            class="text-center p-2 fw-bold"
            [ngStyle]="
              detailData?.can_reopen
                ? selectedJobStatus?.additional_info?.style?.snubbed
                : {
                    color: 'var(--bs-dark)',
                    background: 'var(--bs-dark)'
                  }
            "
          >
            {{ detailData?.can_reopen ? "JOB COMPLETED" : "JOB CLOSED" }}
          </div>

          <div
            class="dropdown"
            *ngIf="
              !offlineMode && detailData?.status?.status_identity?.value !== 1
            "
          >
            <!--FIXME: default status shown is wrong-->
            <!-- <div class="col form-floating pt-2">
            
            <select
              class="form-select"
              [(ngModel)]="selectedStatus"
              (change)="
                changeJobStatus(
                  selectedStatus,
                  jobCancelTemplate,
                  onsiteTimeTempalteRef
                )
              "
              placeholder="Change Status"
            >
              <option
                *ngFor="let item of statusList; let i = index"
                [ngValue]="item"
              >
                {{ item?.status?.name }}
              </option>
            </select>
            <label for=""> Change Status</label>
          </div> -->
            <div class="pt-2" (click)="toggleDropdown()">
              <button
                class="btn btn-primary w-100 btm-btn-height d-flex align-items-center justify-content-center rounded-0"
                (blur)="statusDropdownOpen ? toggleDropdown() : ''"
              >
                Change Status
                <div class="ms-4">
                  @if(statusDropdownOpen){
                  <span>
                    <i class="fas fa-chevron-up"></i>
                  </span>
                  }@else {
                  <span>
                    <i class="fas fa-chevron-down"></i>
                  </span>
                  }
                </div>
              </button>
            </div>
            <div class="dropdown-content-up" *ngIf="statusDropdownOpen">
              <a
                *ngFor="let item of statusList"
                (click)="
                  changeJobStatus(
                    item,
                    jobCancelTemplate,
                    onsiteTimeTempalteRef,
                    inTransitTemplateRef
                  )
                "
                >{{ item?.status?.name }}</a
              >
            </div>
          </div>
        </div>

        <div
          class="p-2"
          *ngIf="![2, 3].includes(detailData?.status?.status_identity?.value)"
        >
          <!-- Comment and Photo buttons -->
          <ng-container
            *ngIf="
              detailData?.status?.status_identity?.value == 1 &&
              detailData?.can_reopen &&
              !offlineMode &&
              lastStatus?.status?.id
            "
          >
            <div
              class="d-flex align-items-center justify-content-center w-100 bg-danger btm-btn-height text-light"
              (click)="moveJobBack()"
            >
              Reopen Job
            </div>
          </ng-container>

          <div
            class="d-flex justify-content-between"
            *ngIf="detailData?.status?.status_identity?.value !== 1"
          >
            <div
              *ngIf="detailData?.service_type?.enable_comments === true"
              class="d-flex align-items-center justify-content-center flex-fill me-1 border border-primary btm-btn-height"
              (click)="openAddCommentTemplate(commentRef)"
            >
              <i class="fa-regular fa-message fa-3x"></i>
            </div>
            <div
              *ngIf="detailData?.service_type?.enable_uploads === true"
              class="d-flex align-items-center justify-content-center flex-fill ms-1 border border-primary btm-btn-height"
              (click)="openCamPopup()"
            >
              <i class="fa-solid fa-camera fa-3x"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="largeView">
      <gtapp-gt-table
        [desktopTableUIData]="
          isAdmin || isDispatchUser ? jobHistory : jobCommentsFiles
        "
        [desktopTableDetails]="jobDetailsTableView"
        [tableStyle]="tableStyle"
        (rowClick)="onTableRowClick($event)"
      ></gtapp-gt-table>

      <!-- SELECTED DETAILS -->
      <ng-container *ngIf="selectedEvent">
        <gtapp-selected-event-detail
          [selectedEvent]="selectedEvent"
          selectedEventFrom="job"
          [extraCheck]="
            !offlineMode &&
            ![1, 2, 3].includes(detailData?.status?.status_identity?.value)
          "
          (editUserComment)="editComment($event, commentRef)"
        ></gtapp-selected-event-detail>
      </ng-container>
    </ng-container>

    <!-- Dialogue for assign users -->
    <ng-template #modifyAssigneeRef let-data let-modifyAssigneeRef="dialogRef">
      <div class="card dialog-box">
        <div class="card-header">
          Select Assignees

          <span *ngIf="detailData?.assignee?.length">
            [{{ detailData?.assignee?.length }} Selected]
          </span>

          <span class="float-end" (click)="modifyAssigneeRef.close()">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div>
          <ng-container
            *ngIf="
              ![1, 2, 3].includes(detailData?.status?.status_identity?.value) &&
              !offlineMode
            "
          >
            <div class="mb-3">
              <gtapp-auto-complete
                [data]="guardListData"
                placeHolder="Search/Select Assignee"
                searchKeyword="full_name"
                [itemTemplate]="assigneeTemplate"
                (inputChanged)="getAssignees($event)"
                (selected)="onAssigneeSelect($event)"
              >
              </gtapp-auto-complete>
            </div>

            <ng-template #assigneeTemplate let-guard>
              <div>
                {{ guard?.full_name ? guard.full_name : guard.email }}
                <small *ngIf="guard?.validStateLicense">
                  <i class="fa-solid fa-check-double"></i>
                </small>
              </div>
            </ng-template>
          </ng-container>
          <div class="overflow-assignee">
            <table class="table my-2">
              <tbody>
                <tr
                  *ngFor="let assignee of assigneeList"
                  class="cursor-pointer"
                >
                  <td>
                    <div class="d-flex">
                      <div
                        class="form-floating"
                        (click)="
                          ![1, 2, 3].includes(
                            detailData?.status?.status_identity?.value
                          )
                            ? offlineMode
                              ? showOfflineMessage()
                              : addRemovessignee(assignee)
                            : ''
                        "
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="assignee?.selected"
                          [disabled]="
                            !(
                              ![1, 2, 3].includes(
                                detailData?.status?.status_identity?.value
                              ) && !offlineMode
                            )
                          "
                        />
                      </div>
                      <div class="flex-grow-1 ms-2">
                        {{ assignee?.full_name }}
                      </div>
                      <div
                        (click)="
                          offlineMode
                            ? showOfflineMessage()
                            : openAssigneeDetail(assignee)
                        "
                      >
                        <i class="fa-solid fa-circle-info"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="mt-3"
            *ngIf="
              jobAssigneeTotalPages > 1 &&
              jobAssigneeTotalCount != assigneeList?.length
            "
          >
            <button
              class="btn btn-secondary btn-lg"
              (click)="onLoadMoreAssignees()"
            >
              Load More
            </button>
          </div>
          <!-- <div class="row row-cols-2 mt-4">
            <div class="col">
              <button
                class="btn btn-lg w-100 btn-dark"
                (click)="modifyAssigneeRef.close()"
              >
                Cancel
              </button>
            </div>

            <div class="col">
              <button
                class="btn btn-primary btn-lg w-100"
                [disabled]="assigneeUpdateDisabling()"
                (click)="updateAssignee()"
              >
                Update
              </button>
            </div>
          </div> -->
        </div>
      </div>
    </ng-template>
    <!-- ADD COMMENT POPOVER / DIALOG -->
    <ng-template #commentRef let-commentRef="dialogRef" let-data>
      <div class="card dialog-box">
        <div class="card-header">
          {{ data?.detail ? "Edit Comment" : "Add Comment" }}
          <span class="float-end" (click)="commentRef.close(); newComment = ''">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div>
          <div class="form-group">
            <textarea
              class="form-control"
              rows="10"
              cols="32"
              wrap="hard"
              maxlength="340"
              type="text"
              placeholder="Start typing"
              [(ngModel)]="newComment"
            ></textarea>
          </div>
          <div class="row row-cols-2 mt-3">
            <div class="col">
              <button
                type="button"
                class="btn btn-dark btn-lg w-100"
                (click)="commentRef.close(); newComment = ''"
              >
                Cancel
              </button>
            </div>
            <div class="col">
              <button
                class="btn btn-primary btn-lg w-100"
                (click)="addComment(data); commentRef.close()"
              >
                {{ data?.detail ? "Update" : "Add" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Cancel Job dialoge -->
    <ng-template #jobCancelTemplate let-jobCancelTemplate="dialogRef">
      <div class="card dialog-box">
        <div class="card-header">
          Confirmation Needed

          <span class="float-end" (click)="jobCancelTemplate.close()">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div>
          <div class="alert alert-danger">
            <div>Cancel Job for {{ detailData?.company?.company_name }}</div>
            <div>WARNING: This action cannot be reversed.</div>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              [(ngModel)]="cancelReason"
              placeholder="Cancellation reason"
            /><label>Cancellation Reason</label>
          </div>
          <div class="row row-cols-2 mt-3">
            <div class="col">
              <button
                type="button"
                class="btn btn-dark btn-lg w-100"
                (click)="jobCancelTemplate.close()"
              >
                Cancel
              </button>
            </div>
            <div class="col">
              <button
                class="btn btn-danger btn-lg w-100"
                [disabled]="!cancelReason"
                (click)="deleteJob(); jobCancelTemplate.close()"
              >
                Cancel Job
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- UPDATE SITE CONTACTS POPOVER / DIALOG -->
    <ng-template #modifySiteContactRef let-modifySiteContactRef="dialogRef">
      <div class="card dialog-box">
        <div class="card-header">
          Onsite Contacts

          <span class="float-end" (click)="modifySiteContactRef.close()">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <table class="table my-2">
          <tbody>
            <tr *ngFor="let contact of siteContactList" class="cursor-pointer">
              <td
                (click)="
                  ![1, 2, 3].includes(
                    detailData?.status?.status_identity?.value
                  ) &&
                  (isAdmin || isDispatchUser)
                    ? offlineMode
                      ? showOfflineMessage()
                      : addRemoveSiteContact(contact)
                    : ''
                "
              >
                <div class="d-flex">
                  <div class="form-floating">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [checked]="contact?.selected"
                      [disabled]="
                        !(
                          ![1, 2, 3].includes(
                            detailData?.status?.status_identity?.value
                          ) &&
                          !offlineMode &&
                          (isAdmin || isDispatchUser)
                        )
                      "
                    />
                  </div>
                  <div class="flex-grow-1 ms-2">
                    {{ contact?.full_name }}
                  </div>
                  <div
                    (click)="
                      offlineMode
                        ? showOfflineMessage()
                        : openSiteContactTemplate(contact)
                    "
                  >
                    <i class="fa-solid fa-circle-info"></i>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="row row-cols-2 mt-3"
          *ngIf="
            (isAdmin || isDispatchUser) &&
            ![1, 2, 3].includes(detailData?.status?.status_identity?.value)
          "
        >
          <div class="col">
            <button
              type="button"
              class="btn btn-dark btn-lg w-100"
              (click)="modifySiteContactRef.close()"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              class="btn btn-primary btn-lg w-100"
              (click)="addLocationContact()"
            >
              Add New Contact
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Additional Information POPOVER / DIALOG -->
    <ng-template #requestRef let-data let-requestRef="dialogRef">
      <div class="card dialog-box">
        <div class="card-header">
          Additional Information
          <span class="float-end" (click)="requestRef.close()">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <!-- Request Detail Section start-->
        <div class="row row-cols-1 gy-2">
          <div class="col">
            <textarea
              class="form-control mb-3"
              placeholder="Additional Information"
              cols="20"
              rows="5"
              wrap="hard"
              maxlength="900"
              fullWidth
              type="text"
              [(ngModel)]="newRequest"
            ></textarea>
          </div>
        </div>
        <div class="row row-cols-2 mt-3">
          <div class="col">
            <button
              type="button"
              class="btn btn-dark btn-lg w-100"
              (click)="requestRef.close()"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              class="btn btn-primary btn-lg w-100"
              (click)="updateRequestDetails(); requestRef.close()"
            >
              Update
            </button>
          </div>
        </div>

        <!-- Request Detail Section end-->
      </div>
    </ng-template>

    <!-- UPDATE MONITORING COMPANY DIALOG -->
    <ng-template #modifyMCTemplate let-modifyMCTemplate="dialogRef">
      <div class="dialog-box card">
        <div class="card-header">
          Monitoring Company
          <span class="float-end" (click)="modifyMCTemplate.close()">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>

        <div class="alert alert-danger">
          WARNING: This cannot be changed once set
        </div>
        <div class="mb-3">
          <gtapp-auto-complete
            [data]="monitoringCompanyList"
            placeHolder="Monitoring Company"
            searchKeyword="company_name"
            [itemTemplate]="MCTemplate"
            [addInitialKey]="isAdmin"
            (inputChanged)="onMcSearch($event)"
            (selected)="addMC($event)"
          >
          </gtapp-auto-complete>
        </div>

        <ng-template #MCTemplate let-item>
          @if (item?.key === 0) {
          <div>Add Monitoring Company</div>
          } @else {
          <div>
            {{ item.company_name }}
          </div>
          }
        </ng-template>
      </div>
    </ng-template>

    <!-- JOB ID DIALOG -->
    <ng-template
      #modifyExternalJobIdTemplate
      let-modifyExternalJobIdTemplate="dialogRef"
    >
      <div class="dialog-box card">
        <div class="card-header">
          External JOB ID<span
            class="float-end"
            (click)="modifyExternalJobIdTemplate.close()"
          >
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>

        <!-- Job Id Section start-->
        <div class="row g-1">
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              placeholder="External Job ID"
              [(ngModel)]="externalNewJobId"
              autocomplete="off"
              maxlength="32"
            />
            <label>External Job ID</label>
          </div>
        </div>
        <div class="row row-cols-2 mt-4">
          <div class="col">
            <button
              class="btn btn-lg w-100 btn-dark"
              (click)="modifyExternalJobIdTemplate.close()"
            >
              Cancel
            </button>
          </div>

          <div class="col">
            <button
              class="btn btn-primary btn-lg w-100"
              [disabled]="externalOldJobId === externalNewJobId"
              (click)="updateJobId(); modifyExternalJobIdTemplate.close()"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- Add OnSite/Offsite datetime model -->
    <ng-template
      #onsiteTimeTempalteRef
      let-onsiteTimeTempalteRef="dialogRef"
      let-data
    >
      <div class="dialog-box card">
        <div class="card-header">
          {{ data.name }} Time
          <span class="float-end" (click)="onsiteTimeTempalteRef.close(false)">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div class="form-floating mb-3">
          <input
            type="datetime-local"
            class="form-control"
            placeholder="Select Time"
            [(ngModel)]="dateTimeValue"
            (change)="changeUserProvidedTime($event)"
            [min]="jobStatusTimeChange?.created_at"
            [max]="jobStatusTimeChange?.currentTime"
          />
          <label>Select Time</label>
        </div>

        <div class="row row-cols-2 mt-3">
          <div class="col">
            <button
              type="button"
              class="btn btn-dark btn-lg w-100"
              (click)="onsiteTimeTempalteRef.close(false)"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              class="btn btn-primary btn-lg w-100"
              (click)="onsiteTimeTempalteRef.close(data)"
              [disabled]="!jobStatusTimeChange?.valid"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- in transit eta tempalte-->
    <ng-template #inTransitTemplateRef let-inTransitTemplateRef="dialogRef">
      <div class="dialog-box card">
        <div class="card-header">
          Estimated Time of Arrival (ETA)
          <span
            class="float-end"
            (click)="inTransitTemplateRef.close()"
            *ngIf="detailData?.showEta"
          >
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div class="d-flex mb-3">
          <div class="flex-grow-1">
            <input
              class="w-100"
              type="range"
              min="0"
              max="60"
              step="5"
              pattern="\d*"
              [(ngModel)]="etaValue"
            />
          </div>
          <div class="fw-semibold ms-2">
            {{
              etaValue === 0
                ? "N/A"
                : etaValue === 60
                ? "60+ Minutes"
                : etaValue + " Minutes"
            }}
          </div>
        </div>

        <div class="form-floating mb-2" *ngIf="detailData?.showEta">
          <input
            class="form-control"
            type="text"
            placeholder="Reason"
            [(ngModel)]="etaChangeReason"
            autocomplete="off"
          />
          <label>Reason</label>
        </div>

        <div>
          <button
            class="btn btn-primary btn-lg w-100"
            (click)="inTransitTemplateRef.close(etaValue)"
            [disabled]="
              etaValue < 0 ||
              etaValue > 60 ||
              (!etaChangeReason && detailData?.showEta)
            "
          >
            Update
          </button>
        </div>
      </div>
    </ng-template>

    <!-- JOB WELFARE INTERVAL DIALOG -->
    <ng-template
      #welfareIntervalTemplate
      let-welfareIntervalTemplate="dialogRef"
    >
      <div class="dialog-box card">
        <div class="card-header">
          Welfare Check Interval
          <span class="float-end" (click)="welfareIntervalTemplate.close()">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>

        <div class="form-floating">
          <select
            class="form-select"
            id="welfareChecks"
            [(ngModel)]="selectedInterval"
            (change)="updateInterval(selectedInterval)"
          >
            <option
              *ngFor="let interval of intervalData"
              [value]="interval.value"
            >
              {{ interval.name }}
            </option>
          </select>
          <label for="">Welfare Check Interval</label>
        </div>
        <div *ngIf="selectedInterval" class="alert">
          <div class="mb-2">
            Guards will be reminded to submit a welfare check
            {{ welfareReminderDict?.[selectedInterval]  }} minutes before the
            interval set above.
          </div>
          <div>
            Maximum inactivity time allowed before a missed welfare check is
            captured:
            <span class="fst-italic"> {{ selectedInterval }} minutes. </span>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template
      #changeScheduleTemplateRef
      let-changeScheduleTemplateRef="dialogRef"
    >
      <div class="dialog-box card">
        <div class="card-header">
          Change Schedule

          <span class="float-end" (click)="changeScheduleTemplateRef.close()">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div class="form-floating mb-3">
          <input
            type="datetime-local"
            class="form-control"
            placeholder="End Time"
            [(ngModel)]="endDateTime"
            [min]="validEndDate"
            (change)="changeEndDateTime($event)"
          />
          <label>End Time</label>
        </div>
        <div class="row row-cols-2 mt-4">
          <div class="col">
            <button
              class="btn btn-lg w-100 btn-dark"
              (click)="changeScheduleTemplateRef.close()"
            >
              Cancel
            </button>
          </div>

          <div class="col">
            <button
              class="btn btn-primary btn-lg w-100"
              (click)="updateSchedule(); changeScheduleTemplateRef.close()"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
