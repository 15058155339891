<!-- If detailData exists, display the content -->
<ng-container *ngIf="detailData?.id">
  <div class="row row-cols-1 row-cols-lg-2 gy-2">
    <div
      class="col d-md-none mb-2"
      *ngIf="
        !detailData?.is_validated && isAdmin && detailData?.address?.state_code
      "
    >
      <button
        class="btn btn-danger btn-lg w-100 btn-lg"
        (click)="openTemplate(validateCPTemplate)"
      >
        Validate Checkpoint
      </button>
    </div>
    <div class="col-lg-6">
      <div class="d-flex">
        <div class="flex-grow-1">
          <dl class="cursor-pointer">
            <dt>
              Checkpoint Name
              <span
                (click)="
                  isAdmin
                    ? detailData?.is_validated
                      ? openTemplate(editCPTemplate)
                      : openTemplate(validateCPTemplate)
                    : ''
                "
                *ngIf="
                  isAdmin
                    ? detailData?.is_validated
                      ? true
                      : detailData?.address?.state_code
                      ? true
                      : false
                    : false
                "
              >
                <i class="fa-solid fa-pen-to-square"></i>
              </span>
            </dt>

            <dd>{{ detailData?.name }}</dd>
          </dl>
        </div>
        <div class="me-lg-2" *ngIf="isAdmin">
          <button
            class="btn btn-sm btn-danger"
            (click)="deleteCheckPoint(detailData)"
          >
            Delete
          </button>
        </div>
      </div>

      <div class="col d-lg-none" *ngIf="!largeView">
        <gtapp-map-leaflet
          [latLon]="{
            lat: detailData?.latitude,
            lon: detailData?.longitude,
            geoFenceRadius: detailData?.min_distance
          }"
          mapId="mobileMap"
        >
        </gtapp-map-leaflet>
      </div>
      <div class="col">
        <dl>
          <dt>Checkpoint Address</dt>

          <dd>
            {{
              detailData?.is_validated
                ? detailData?.address?.full_address.trim() ||
                  "No Address Available"
                : detailData?.address?.full_address.trim() ||
                  "Address is being fetched and will be available soon"
            }}
          </dd>
        </dl>
      </div>
      <div class="col">
        <dl (click)="detailData?.site ? redirect('site') : null">
          <dt>Site <i class="fa-solid fa-link"></i></dt>

          <dd>
            {{ detailData?.site ? detailData?.site : "Site Not Assigned" }} [{{
              detailData?.company
            }}]
          </dd>
        </dl>
      </div>

      <div class="col">
        <dl>
          <dt>Geofence Radius</dt>

          <dd>
            @if(detailData?.is_validated){
            <ng-container>
              <form [formGroup]="checkPointEditForm" class="me-3">
                <span class="badge-value">
                  <input
                    class="range-slider-input m-0"
                    style="width: 100%"
                    type="range"
                    [min]="minKm"
                    placeholder="Geofence Radius (metres)"
                    [max]="maxKm"
                    [value]="nearestKmValue"
                    formControlName="min_distance"
                    (mouseup)="updateCP(checkPointEditForm)"
                    (touchend)="updateCP(checkPointEditForm)"
                  />

                  <div class="form-floating">
                    <input
                      class="form-control"
                      id="description"
                      formControlName="min_distance"
                      type="tel"
                      [min]="minKm"
                      placeholder="Geofence Radius (metres)"
                      [max]="maxKm"
                      (blur)="updateGeoDistance()"
                      pattern="\d*"
                    /><label for="inputAssignee"
                      >Geofence Radius (metres)</label
                    >
                  </div>
                </span>
              </form> </ng-container
            >}@else {
            <ng-container>
              {{ detailData?.min_distance | number : "1.0-0" }}
              metres </ng-container
            >}
          </dd>
        </dl>
      </div>
      <div class="col" *ngIf="detailData?.description">
        <dl>
          <dt>Description</dt>

          <dd>
            {{ detailData?.description }}
          </dd>
        </dl>
      </div>
      <div class="col" *ngIf="detailData?.qr_code">
        <dl>
          <dt>Checkpoint Value</dt>

          <dd>
            <div class="d-flex">
              <div>
                <img
                  src="assets/images/qr-code-logo.avif"
                  alt=""
                  class="me-1"
                  width="38"
                />
              </div>
              <div class="flex-grow-1 d-flex align-items-center">
                <p class="text-break">
                  {{ detailData?.qr_code }}
                </p>
              </div>
            </div>
          </dd>
        </dl>
      </div>

      <ng-container *ngIf="detailData?.user_prompts?.length">
        <div class="col">
          <dl>
            <dt (click)="isAdmin ? openTemplate(postScanPromptTemplate) : ''">
              Post Scan Prompts
              <span class="ms-2">
                <i class="fa-solid fa-pen-to-square" *ngIf="isAdmin"></i>
              </span>
            </dt>

            <dd>
              <div class="row row-cols-auto mt-2 g-3">
                <div
                  *ngFor="let prompt of detailData?.user_prompts"
                  (click)="isAdmin ? getPromptDetail(prompt) : ''"
                >
                  <button class="btn btn-dark btn-sm">
                    {{ prompt?.name }}
                  </button>
                </div>
              </div>
            </dd>
          </dl>
        </div>
      </ng-container>

      <div class="col" *ngIf="isAdmin">
        <div class="row row-cols-1 row-cols-lg-3 gy-2 mt-4">
          <div
            class="col"
            *ngIf="!detailData?.is_validated && detailData?.address?.state_code"
          >
            <button
              class="btn btn-danger btn-lg w-100 btn-lg"
              (click)="openTemplate(validateCPTemplate)"
            >
              Validate Checkpoint
            </button>
          </div>
          <div
            class="col"
            *ngIf="detailData?.scanned_count && detailData?.is_validated"
          >
            <button
              class="btn btn-secondary w-100 btn-lg"
              (click)="onClickScanHistory()"
            >
              Scan History
            </button>
          </div>
          <div
            *ngIf="
              !detailData?.user_prompts?.length && detailData?.is_validated
            "
          >
            <button
              class="btn btn-primary w-100 btn-lg"
              (click)="openTemplate(postScanPromptTemplate)"
            >
              Add Post Scan Prompt
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 d-none d-lg-block" *ngIf="largeView">
      <gtapp-map-leaflet
        [latLon]="{
          lat: detailData?.latitude,
          lon: detailData?.longitude,
          geoFenceRadius: detailData?.min_distance
        }"
        mapId="mobileMapLargeView"
      >
      </gtapp-map-leaflet>
    </div>
  </div>

  <div class="d-none d-lg-block">
    <gtapp-gt-table
      [desktopTableUIData]="checkpointHistoryData"
      [desktopTableDetails]="checkpointHistoryTable"
      (rowClick)="onCPHistoryClick($event)"
    ></gtapp-gt-table>
  </div>
  <ng-container *ngIf="selectedHistoryEvent">
    <gtapp-selected-event-detail
      [selectedEvent]="selectedHistoryEvent"
    ></gtapp-selected-event-detail>
  </ng-container>

  <!-- Edit Checkpoint Dialog Template (example simplified for clarity) -->
  <ng-template #editCPTemplate let-editCPRef="dialogRef">
    <div class="card dialog-box">
      <div class="card-header">
        Edit Checkpoint
        <span class="float-end" (click)="editCPRef.close(); resetValues()">
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>

      <form [formGroup]="checkPointEditForm">
        <ng-container *ngIf="currentEditCPStep === 1">
          <div class="row row-cols-1 g-2">
            <div class="form-floating">
              <input
                class="form-control"
                formControlName="name"
                type="text"
                placeholder="Checkpoint Name"
                (keyup.enter)="currentEditCPStep = 2"
              />
              <label for="inputService">Checkpoint Name</label>
            </div>

            <div class="form-floating">
              <input
                class="form-control"
                fullWidth
                id="description"
                formControlName="description"
                type="text"
                placeholder="Description (Optional)"
              /><label for="inputAssignee">Description (Optional)</label>
            </div>
            <div
              class="mt-4 d-flex align-items-center"
              *ngIf="detailData?.qr_code"
            >
              <!-- <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="is_locked"
                  id="is_locked"
                  name="is_locked"
                  formControlName="is_locked"
                />
                <label class="form-check-label" for="is_locked">
                  Lock Checkpoint
                </label>
              </div> -->
              <div class="form-check my-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="is_locked"
                  name="is_locked"
                  formControlName="is_locked"
                />
                <label class="form-check-label small" for="is_locked">
                  Lock Checkpoint
                </label>
              </div>
              <span
                class="ms-2"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="When box is ticked, checkpoint can only be scanned at checkpoint location."
              >
                <i class="fa-solid fa-circle-info"></i>
              </span>
            </div>
          </div>
          <div class="row row-cols-2 mt-4">
            <div>
              <button
                type="button"
                class="btn btn-dark w-100 btn-lg"
                (click)="editCPRef.close(); resetValues()"
              >
                Cancel
              </button>
            </div>
            <ng-container *ngIf="detailData?.qr_code">
              <div *ngIf="checkPointEditForm.value.is_locked">
                <button
                  type="button"
                  class="btn btn-primary w-100 btn-lg"
                  (click)="showMapTrue(); currentEditCPStep = 2"
                >
                  Update Location
                </button>
              </div>
              <div *ngIf="!checkPointEditForm.value.is_locked">
                <button
                  type="button"
                  class="btn btn-primary w-100 btn-lg"
                  (click)="showMapTrue(); currentEditCPStep = 2"
                >
                  Update
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="!detailData?.qr_code">
              <!-- normal checkpoint cases-->
              <div>
                <button
                  type="button"
                  class="btn btn-primary w-100 btn-lg"
                  (click)="showMapTrue(); currentEditCPStep = 2"
                >
                  Update Location
                </button>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="currentEditCPStep === 2">
          <div class="row row-cols-2 my-3">
            <div>
              <button
                class="btn btn-dark-light w-100 btn-lg"
                (click)="showMap = false; currentEditCPStep = 1"
              >
                Back
              </button>
            </div>
            <div>
              <button
                class="btn btn-primary w-100 btn-lg"
                [disabled]="!checkPointEditForm.valid || pinIsFar"
                (click)="updateCP(checkPointEditForm)"
              >
                Update
              </button>
            </div>
          </div>

          <div *ngIf="showMap && gps">
            <div *ngIf="pinIsFar" class="alert alert-danger">
              Pin is too far from Address
            </div>
            <gtapp-map-leaflet
              [latLon]="gps"
              [mapId]="'checkpointEditmapid'"
              [cursorDraggable]="true"
              (emitData)="emitData($event, true)"
              (updateMinDistance)="updateMinDistance($event)"
            ></gtapp-map-leaflet>

            <div class="text-center">Drag the marker to set coordinates</div>
          </div>
        </ng-container>
      </form>
    </div>
  </ng-template>

  <!--validate checkpoint template-->
  <ng-template #validateCPTemplate let-validateCPTemplate="dialogRef">
    <div class="card dialog-box">
      <div class="card-header">
        Validate Checkpoint
        <span
          class="float-end"
          (click)="validateCPTemplate.close(); resetValues()"
        >
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>

      <form [formGroup]="checkPointForm">
        <ng-container *ngIf="currentValidateStep === 1">
          <div class="form-floating">
            <input
              class="form-control"
              formControlName="name"
              type="text"
              placeholder="Checkpoint Name"
              (keyup.enter)="currentValidateStep = 2"
            />
            <label for="inputService">Checkpoint Name</label>
          </div>

          <div class="mt-4 row row-cols-2">
            <div class="col">
              <button
                class="btn btn-dark btn-lg w-100"
                (click)="validateCPTemplate.close(); resetValues()"
              >
                Cancel
              </button>
            </div>
            <div class="col">
              <button
                class="btn btn-primary btn-lg w-100"
                (click)="currentValidateStep = 2"
                [disabled]="!checkPointForm.value?.company_id"
                (click)="showMapTrue()"
              >
                Next
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="currentValidateStep === 2">
          <div class="mb-3">
            <gtapp-auto-complete
              [data]="addrLookupData"
              placeHolder="Address Lookup"
              searchKeyword="company_name"
              [itemTemplate]="addressTemplate"
              [addInitialKey]="isAdmin"
              (inputChanged)="onAddressSearch($event)"
              (selected)="addressSelected($event)"
            >
            </gtapp-auto-complete>
          </div>

          <ng-template #addressTemplate let-item>
            @if (item?.key === 0) {
            <div>Enter address manually</div>
            } @else {
            <div>
              {{ item.full_address }}
            </div>
            }
          </ng-template>

          <div *ngIf="showMap && gps">
            <gtapp-map-leaflet
              [latLon]="gps"
              [mapId]="'mapid'"
              [cursorDraggable]="true"
              (emitData)="emitData($event)"
              (updateMinDistance)="updateMinDistance($event)"
            ></gtapp-map-leaflet>
            <div
              *ngIf="
                checkPointForm.value?.latitude &&
                checkPointForm.value?.longitude
              "
              class="text-center"
            >
              Drag the marker to set coordinates
            </div>
          </div>
          <div class="mt-4 row row-cols-2">
            <div class="col">
              <button
                class="btn btn-dark btn-lg w-100"
                (click)="currentValidateStep = 1"
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                class="btn btn-primary btn-lg w-100"
                (click)="currentValidateStep = 3"
                [disabled]="!checkPointForm.value?.latitude"
              >
                Next
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="currentValidateStep === 3">
          <div
            *ngIf="!detailData?.is_validated"
            class="alert alert-danger"
            role="alert"
          >
            <small>
              WARNING: Client and location cannot be changed once set</small
            >
          </div>
          <div class="mb-3">
            <gtapp-auto-complete
              [data]="clientLookupData"
              placeHolder="Client"
              searchKeyword="company_name"
              [initialValue]="checkPointForm.value.company"
              [itemTemplate]="clientTemplate"
              [addInitialKey]="true"
              (inputChanged)="onClientSearch($event)"
              (selected)="onClientSelect($event)"
            >
            </gtapp-auto-complete>
          </div>

          <ng-template #clientTemplate let-item>
            @if (item?.key === 0) {
            <div>Add Client</div>
            } @else {
            <div>
              <div class="fw-bold">
                {{ item?.company_name }}
              </div>
              <div>
                <small>{{ item?.address?.full_address }}</small>
              </div>
            </div>
            }
          </ng-template>

          <div class="row row-cols-2 mt-4">
            <div class="col">
              <button
                class="btn btn-dark w-100 btn-lg"
                (click)="currentValidateStep = 2"
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                type="button"
                class="btn btn-primary w-100 btn-lg"
                [disabled]="!checkPointForm.valid"
                (click)="
                  updateCP(checkPointForm, true); validateCPTemplate.close()
                "
              >
                Validate
              </button>
            </div>
          </div>
        </ng-container>
      </form>
    </div>
  </ng-template>

  <!--Post Scan prompt Tempalte-->
  <ng-template #postScanPromptTemplate let-postScanPromptTemplate="dialogRef">
    <div class="dialog-box card">
      <div class="card-header">
        Post Scan Prompts
        <span class="float-end" (click)="postScanPromptTemplate.close()">
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>

      <div>
        <gtapp-auto-complete
          [data]="userPromptsSearchResults"
          placeHolder="Search Prompts"
          searchKeyword="name"
          [itemTemplate]="promptLookupTemplate"
          (inputChanged)="searchPrompts($event)"
          (selected)="addSelectedUserPrompt($event)"
        >
        </gtapp-auto-complete>
      </div>

      <ng-template #promptLookupTemplate let-item>
        <div class="fw-bold">
          {{ item?.name }}
        </div>
      </ng-template>
      <ng-container>
        <table class="table">
          <tbody
            cdkDropList
            #currentList="cdkDropList"
            [cdkDropListData]="selectedUserPrompts"
            class="drag-drop-container drag-list mt-3"
            (cdkDropListDropped)="drop($event)"
          >
            <tr
              cdkDrag
              [cdkDragDisabled]="!prompt.selected"
              *ngFor="let prompt of availableUserPrompts; let i = index"
              class="cursor-pointer border-bottom"
              [ngClass]="prompt.selected ? 'cursor-move' : ''"
              (click)="addRemoveUserPrompt(prompt)"
            >
              <td class="">
                <input
                  type="checkbox"
                  class="form-check-input"
                  [checked]="prompt?.selected"
                />
              </td>

              <td>
                <div class="d-flex align-items-center">
                  <span
                    *ngIf="prompt.selected && selectedUserPrompts?.length > 1"
                    class="me-2"
                  >
                    <i class="fa-solid fa-bars"></i>
                  </span>
                  <div>
                    {{ prompt?.name }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="mt-3"
          *ngIf="
            userPromptsTotalPages > 1 &&
            userPromptsTotalCount != availableUserPrompts?.length
          "
        >
          <button class="btn btn-secondary btn-lg" (click)="onLoadMore()">
            Load More
          </button>
        </div>

        <div class="row row-cols-2 mt-3">
          <div class="col">
            <button
              class="btn btn-dark w-100 btn-lg"
              (click)="postScanPromptTemplate.close()"
            >
              Cancel
            </button>
          </div>

          <div class="col">
            <button
              type="button"
              class="btn btn-primary w-100 btn-lg"
              (click)="updateCP(checkPointEditForm)"
            >
              Update
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</ng-container>
