<div class="d-flex flex-column flex-md-row input-btn-group">
  <div class="flex-md-grow-1 mb-2 mb-md-0">
    <gtapp-auto-complete
      [data]="dropDownJobs"
      placeHolder="Search Jobs"
      searchKeyword="name"
      [initialValue]="globalFilter"
      [itemTemplate]="cpLookupTemplate"
      (inputChanged)="onFilterApply($event)"
      (selected)="openIndividualJob($event)"
      (submitted)="globalFilter = $event; onFilterApply($event)"
    >
    </gtapp-auto-complete>

    <ng-template #cpLookupTemplate let-item>
      <div>
        <div class="fw-bold">{{ item.job_key }} ({{ item.status }})</div>
        <div>{{ item.company }}</div>
      </div>
    </ng-template>
  </div>
  <div class="ms-md-2 mb-2 mb-lg-0">
    <button class="btn btn-primary w-100 btn-lg" (click)="openAddJobForm()">
      + Alarm Response
    </button>
  </div>
  <div class="ms-md-2 mb-2 mb-lg-0 d-flex justify-content-between">
    <button
      class="btn btn-secondary w-100 btn-lg me-2"
      (click)="showAllJobsDesktop()"
    >
      {{ defaultJob ? "Show All" : "Reset" }}
    </button>
    <button
      class="btn btn-secondary w-100 btn-lg d-md-none"
      (click)="isSortPopupVisisble = !isSortPopupVisisble"
    >
      <i class="fa-solid fa-arrow-up-wide-short fa-lg"></i>
    </button>
    <!-- sort popover -->
    <div
      class="dropdown-menu shadow animated--grow-in sort-popover-position"
      [ngClass]="{
        'd-none': !isSortPopupVisisble,
        'd-block': isSortPopupVisisble
      }"
      id="popoverContent"
    >
      <div class="card">
        <div class="p-2">
          <div
            class="row align-items-center mb-2"
            *ngFor="let item of jobDetailsTableView"
            (click)="sortField(item)"
          >
            <div class="col-10 pe-0">
              <button class="btn btn-outline-primary w-100 text-start">
                {{ item.header }}
              </button>
            </div>
            <div class="col-2 text-center ps-0">
              <span *ngIf="item?.sortOrder">
                <i class="fa fa-arrow-down"></i>
              </span>
              <span *ngIf="!item?.sortOrder">
                <i class="fa fa-arrow-up"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!largeView">
  <gtapp-infinite-list
    [mobileCardBackendData]="infiniteJobList"
    [mobileCardDetails]="jobCardsDetails"
    [otherParams]="otherParams"
    [totalRows]="totalRows"
    (loadMoreData)="loadMoreJobs()"
    (rowClick)="openIndividualJob($event)"
    (searchColumn)="searchColumn($event)"
    (sortColumn)="sortColumn($event)"
  ></gtapp-infinite-list>
</div>

<div *ngIf="largeView">
  <gtapp-gt-table
    [desktopTableBackendData]="listJobData"
    [desktopTableDetails]="jobDetailsTableView"
    [tableStyle]="tableStyle"
    [otherParams]="otherParams"
    (paginationChange)="onChangePagination($event)"
    (sortColumn)="sortColumn($event)"
    (searchColumn)="searchColumn($event)"
    (rowClick)="openIndividualJob($event)"
  >
  </gtapp-gt-table>
</div>
