<div class="background-color mt-1">
  <div class="section main-part-scroll">
    <div class="fullWidth">
      <ng-container *ngIf="isPageLoad">
        <ng-container *ngIf="updateTask === 'updateJob'">
          <div
            [ngStyle]="{
              'padding-bottom': bottomSectionFixedHeight
            }"
          >
            <div class="row row-cols-md-2 row-cols-xl-4 g-2">
              <ng-container *ngIf="!offlineMode">
                <!-- SOS -->
                <div *ngIf="!largeView">
                  <div *ngIf="isSOSButtonPressed" class="overlay"></div>
                  <div
                    class="sos-btn-container"
                    [ngClass]="
                      isSOSButtonPressed ? 'sos-btn-container-highlight' : ''
                    "
                  >
                    <button
                      class="btn btn-danger w-100 btn-lg"
                      [class.pressed]="isSOSButtonPressed"
                      (mousedown)="
                        handleSOSPress(
                          true,
                          alertSOSTemplate,
                          confirmSOSTemplate
                        )
                      "
                      (mouseup)="
                        handleSOSPress(
                          false,
                          alertSOSTemplate,
                          confirmSOSTemplate
                        )
                      "
                      (mouseleave)="
                        handleSOSPress(
                          false,
                          alertSOSTemplate,
                          confirmSOSTemplate
                        )
                      "
                      (touchstart)="
                        handleSOSPress(
                          true,
                          alertSOSTemplate,
                          confirmSOSTemplate
                        )
                      "
                      (touchend)="
                        handleSOSPress(
                          false,
                          alertSOSTemplate,
                          confirmSOSTemplate
                        )
                      "
                    >
                      Send SOS Alert
                    </button>
                  </div>
                </div>
                <!-- Install App -->
                <div
                  *ngIf="
                    !isAppInstalled &&
                    isPageLoad &&
                    this.serviceWorkerEnabled &&
                    !largeView
                  "
                >
                  <button
                    class="btn-lg btn btn-light w-100"
                    (click)="installPWAApp()"
                  >
                    Install App
                  </button>
                </div>

                <!-- count of client with checkpoints that do not have any report users configured -->
                <div
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  *ngIf="isAdmin && dashBoardData?.add_report_users > 0"
                  title="Client with checkpoints that do not have any report users configured"
                >
                  <button
                    class="btn-lg btn btn-warning w-100 fs-6"
                    (click)="redirectToPage('addReportUsers')"
                  >
                    Clients not setup for reports
                  </button>
                </div>

                <div
                  *ngIf="
                    (isAdmin || isDispatchUser) &&
                    dashBoardData?.invalid_qr_checkpoints > 0
                  "
                >
                  <button
                    class="btn-lg btn btn-danger w-100 fs-6"
                    (click)="redirectToPage('validateQr')"
                  >
                    Validate Checkpoints
                  </button>
                </div>
                <div *ngIf="isPageLoad && subscriberStats?.company_count != 0">
                  <button
                    class="btn-lg btn btn-warning w-100"
                    (click)="redirectToPage('incidents')"
                  >
                    Incidents
                  </button>
                </div>
                <div *ngIf="dashBoardData?.missed_welfare_checks > 0">
                  <button
                    class="btn-lg btn btn-warning w-100"
                    (click)="
                      openMissedWelfareCheckInEvents(
                        missedEventReasonTemplateRef
                      )
                    "
                  >
                    Missed Welfare Check Ins
                  </button>
                </div>
                <ng-container
                  *ngIf="
                    subscriberStats?.checkpoint_count === 0 ||
                    subscriberStats?.company_count === 0 ||
                    subscriberStats?.job_count == 0
                  "
                >
                  <!-- Add Client -->
                  <div
                    *ngIf="
                      subscriberStats?.company_count == 0 &&
                      (isAdmin || isDispatchUser)
                    "
                  >
                    <button
                      class="btn-lg btn btn-primary w-100"
                      [routerLink]="['/client']"
                    >
                      + Add Site
                    </button>
                  </div>

                  <!-- Add Checkpoint -->
                  <div
                    *ngIf="
                      subscriberStats?.checkpoint_count === 0 &&
                      subscriberStats?.company_count > 0 &&
                      isAdmin
                    "
                  >
                    <button
                      class="btn-lg btn btn-primary w-100"
                      [routerLink]="['/checkpoints']"
                    >
                      + Add Checkpoint
                    </button>
                  </div>
                </ng-container>

                <!-- Patrol History -->
                <div
                  *ngIf="
                    subscriberStats?.log_count > 0 &&
                    (isAdmin || isDispatchUser)
                  "
                >
                  <button
                    class="btn-lg btn btn-secondary w-100"
                    (click)="redirectToPage('patrolHistory')"
                  >
                    Patrol History
                  </button>
                </div>

                <!-- Last 24 Hrs -->
                <div *ngIf="isGuardUser">
                  <button
                    class="btn-lg btn btn-secondary w-100"
                    (click)="redirectToPage('eventLog')"
                  >
                    Event Log
                  </button>
                </div>

                <!-- Guard Tracker Last Live Location -->
                <div
                  *ngIf="
                    (isAdmin || isDispatchUser) &&
                    subscriberStats?.live_count > 0
                  "
                >
                  <button
                    class="btn-lg btn btn-secondary w-100"
                    (click)="redirectToPage('guardLocations')"
                  >
                    Guard Locations
                  </button>
                </div>

                <!-- Unassigned Jobs -->
                <div *ngIf="jobStats?.unassigned_jobs && isAdmin">
                  <button
                    class="btn-lg btn btn-danger w-100"
                    (click)="redirectToPage('unassignedJobs')"
                  >
                    Unassigned Jobs: {{ jobStats?.unassigned_jobs }}
                  </button>
                </div>

                <!-- Stale Jobs -->
                <div
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  *ngIf="jobStats?.stale_jobs && (isAdmin || isDispatchUser)"
                  title="Last update > 3 days"
                >
                  <button
                    class="btn-lg btn btn-danger w-100"
                    (click)="redirectToPage('staleJobs')"
                  >
                    Stale Jobs: {{ jobStats?.stale_jobs }}
                  </button>
                </div>
              </ng-container>

              <!-- User Prompts -->
              <ng-container *ngIf="userPrompts?.length > 0">
                <!-- prompt btns-->
                <div *ngFor="let prompt of userPrompts">
                  <button
                    class="btn btn-primary btn-lg w-100"
                    (click)="
                      updateEvenDetails.promptData = prompt;
                      updateTask = 'userPromptInfo'
                    "
                  >
                    {{ prompt?.name }}
                  </button>
                </div>
              </ng-container>
            </div>

            <div class="m-0 p-0" *ngIf="!largeView">
              <gtapp-update-events
                [detailType]="updateTask"
                (emitData)="closeDetailedHome($event)"
              >
              </gtapp-update-events>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <!--update tasks -->
      <div
        *ngIf="
          [
            'scanCheckPoint',
            'addClientComment',
            'addClientPhoto',
            'userPromptInfo'
          ].includes(updateTask)
        "
      >
        <div>
          <gtapp-update-events
            [detailType]="updateTask"
            [details]="updateEvenDetails"
            (emitData)="closeDetailedHome($event)"
          ></gtapp-update-events>
        </div>
      </div>
      <ng-container *ngIf="userData?.profile?.is_superuser !== true">
        <div class="section" *ngIf="!largeView">
          <div class="bottom-section" id="fixedBtn">
            <!-- online buttons (check conditions are different) -->
            <div *ngIf="!offlineMode">
              <ng-container *ngIf="updateTask == 'updateJob'">
                <div class="p-2">
                  <div
                    *ngIf="subscriberStats?.checkpoint_count != 0"
                    class="mb-2 d-flex justify-content-between"
                  >
                    <div
                      class="me-1 d-flex align-items-center justify-content-center w-100 bg-primary btm-btn-height"
                      (click)="updateTask = 'scanCheckPoint'"
                    >
                      Scan Checkpoint
                    </div>
                    <div
                      class="ms-1 d-flex align-items-center justify-content-center w-100 bg-primary btm-btn-height"
                      [ngClass]="{ disabled: !cameraOnDevice }"
                      (click)="openQRCode()"
                      [style.pointer-events]="!cameraOnDevice ? 'none' : 'auto'"
                      [style.opacity]="!cameraOnDevice ? 0.5 : 1"
                    >
                      Scan QR
                    </div>
                  </div>
                  <div
                    *ngIf="subscriberStats?.company_count != 0"
                    class="d-flex justify-content-between"
                  >
                    <div
                      class="d-flex align-items-center justify-content-center flex-fill me-1 border border-primary btm-btn-height"
                      (click)="updateTask = 'addClientComment'"
                    >
                      <i class="fa-regular fa-message fa-3x"></i>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center flex-fill ms-1 border border-primary btm-btn-height"
                      (click)="updateTask = 'addClientPhoto'"
                    >
                      <i class="fa-solid fa-camera fa-3x"></i>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <!-- offline buttons (check conditions are different) -->

            <div *ngIf="offlineMode">
              <ng-container *ngIf="updateTask == 'updateJob'">
                <div class="p-2">
                  <div *ngIf="offlineData?.savedData?.length" class="mb-2">
                    <div
                      class="d-flex align-items-center justify-content-center w-100 bg-dark text-white btm-btn-height"
                      (click)="openQueuedTemplate(queuedEventsTemplateRef)"
                    >
                      {{ offlineData?.savedData?.length }} Queued Events
                    </div>
                  </div>
                  <div
                    *ngIf="offlineData?.checkpointData?.data?.length"
                    class="mb-2 d-flex justify-content-between"
                  >
                    <div
                      class="me-1 d-flex align-items-center justify-content-center w-100 bg-primary btm-btn-height"
                      (click)="updateTask = 'scanCheckPoint'"
                    >
                      Scan Checkpoint
                    </div>
                    <div
                      class="ms-1 d-flex align-items-center justify-content-center w-100 bg-primary btm-btn-height"
                      [ngClass]="{ disabled: !cameraOnDevice }"
                      (click)="openQRCode()"
                      [style.pointer-events]="!cameraOnDevice ? 'none' : 'auto'"
                      [style.opacity]="!cameraOnDevice ? 0.5 : 1"
                    >
                      Scan QR
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <ng-container
                      *ngIf="offlineData?.siteData?.data?.length"
                      class="flex-fill me-1"
                    >
                      <div
                        class="d-flex align-items-center justify-content-center flex-fill me-1 border border-primary btm-btn-height"
                        (click)="updateTask = 'addClientComment'"
                      >
                        <i class="fa-regular fa-message fa-3x"></i>
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-center flex-fill ms-1 border border-primary btm-btn-height"
                        (click)="updateTask = 'addClientPhoto'"
                      >
                        <i class="fa-solid fa-camera fa-3x"></i>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <!-- show user they dont have internet connection on large screem-->
        <div class="section d-none d-md-block" *ngIf="largeView && offlineMode">
          <div class="alert alert-danger text-center">
            No Network Connection

            <div class="small">
              Please make sure you have a reliable internet connection.
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #queuedEventsTemplateRef let-queuedEventsTemplateRef="dialogRef">
  <div class="card dialog-box">
    <div class="card-header">
      Queued Events
      <span (click)="queuedEventsTemplateRef.close()" class="float-end">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <!--api returns an empty client list-->
    <div *ngFor="let savedItem of offlineData?.savedData">
      <ng-container>
        <div class="card">
          <div>
            <dt>Time</dt>
            <dd>
              {{ savedItem?.updated_at * 1000 | customDate : "d MMM y HH:mm" }}
            </dd>
          </div>
          <div *ngIf="savedItem?.pathName === '/api/patrol/scan_checkpoint/'">
            <dt>Patrol Event</dt>
            <dd>{{ savedItem?.qr_text ? "QR Scan" : "Checkpoint Scan" }}</dd>
            <div>
              <dt>Checkpoint</dt>
              <dd>
                {{ (savedItem?.checkpointDetail | misc : "jsonParse")?.name }}
              </dd>
            </div>
            <div>
              <dt>Client</dt>
              <dd>
                {{
                  (savedItem?.checkpointDetail | misc : "jsonParse")?.company
                }}
              </dd>
            </div>
          </div>
          <div *ngIf="savedItem?.pathName === '/api/patrol/add_update/'">
            <dt>Patrol Event</dt>
            <dd>
              {{
                savedItem?.file
                  ? "Add Photo"
                  : savedItem?.comment
                  ? "Add Comment"
                  : "Update"
              }}
            </dd>
            <div>
              <dt>Client</dt>
              <dd>
                {{ savedItem?.company }}
              </dd>
            </div>
          </div>
          <div *ngIf="savedItem?.pathName === '/api/job/update_job/'">
            <dt>JOB Event</dt>
            <dd>Add Comment/Photo</dd>
            <dt>JOB</dt>
            <dd>{{ savedItem?.jobDetails?.job_key }}</dd>
            <dt>Client</dt>
            <dd>{{ savedItem?.jobDetails?.company }}</dd>
          </div>
          <div *ngIf="savedItem?.pathName === '/api/check_point/register_qr/'">
            <dl>
              <dt>Patrol Event</dt>
              <dd>QR Register</dd>
            </dl>
            <dl>
              <dt>Checkpoint</dt>
              <dd>(savedItem?.form_data | misc : "jsonParse")?.name</dd>
            </dl>
            <dl>
              <dt>Client</dt>
              <dd>(savedItem?.form_data | misc : "jsonParse")?.company</dd>
            </dl>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="mt-3" (click)="queuedEventsTemplateRef.close()">
      <button class="btn btn-lg btn-dark w-100">Cancel</button>
    </div>
  </div>
</ng-template>

<ng-template #alertSOSTemplate let-alertSOSTemplate="dialogRef">
  <div class="dialog-box card p-2">
    <div class="card-header">
      SOS Not Sent

      <span class="float-end" (click)="alertSOSTemplate.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="alert alert-primary">
        Press and hold for at least 3 seconds to send SOS.
      </div>
    </div>
    <div>
      <button
        type="button"
        class="btn btn-primary btn-lg w-100"
        (click)="alertSOSTemplate.close()"
      >
        OK
      </button>
    </div>
  </div>
</ng-template>
<ng-template #confirmSOSTemplate let-confirmSOSTemplate="dialogRef">
  <div class="dialog-box card p-2">
    <div class="card-header">
      SOS Sent
      <span class="float-end" (click)="confirmSOSTemplate.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="alert alert-primary">
        SOS has been sent, parties have been informed to contact you shortly.
      </div>
    </div>
    <div>
      <button
        type="button"
        class="btn btn-primary btn-lg w-100"
        (click)="confirmSOSTemplate.close()"
      >
        OK
      </button>
    </div>
  </div>
</ng-template>

<!-- missed event reason providing template-->
<ng-template
  #missedEventReasonTemplateRef
  let-missedEventReasonTemplateRef="dialogRef"
>
  <div class="dialog-box card p-2">
    <div class="card-header">
      Missed Check Ins
      <span class="float-end" (click)="missedEventReasonTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div class="row row-cols-1 row-cols-md-2 row-cols-xxl-3 row-cols-xxxl-6">
      <div *ngFor="let event of missedWelfareData" class="col gy-2 card">
        <div class="text-center small">
          Check In Missed At: {{ event.missed_at | date : "d MMM HH:mm" }}
        </div>
        <div class="mt-2">
          <textarea
            class="form-control"
            fullWidth
            type="text"
            placeholder="Missed Reason"
            [(ngModel)]="event.missedReason"
            rows="5"
          ></textarea>
        </div>
        <div class="my-2">
          <button
            type="button"
            class="btn btn-primary btn-lg w-100"
            [disabled]="!event?.missedReason"
            (click)="
              updateMissedReason(event);
              missedWelfareData?.length == 1
                ? missedEventReasonTemplateRef.close()
                : ''
            "
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
