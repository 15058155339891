<div class="mb-2 fw-bold">
  Add Job
  <span class="cursor-pointer">
    {{ selectedStatus?.name ? " > " + selectedStatus?.name : "" }}</span
  >
</div>

<div class="row row-cols-1 row-cols-lg-2">
  <div class="col">
    <form [formGroup]="formdata">
      <ng-container *ngIf="currentStep === 1">
        <div class="row row-cols-2 my-3">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              [routerLink]="['/roster']"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="!otherJobType.site.data"
              (click)="currentStep = 2"
            >
              Next
            </button>
          </div>
        </div>
        <!-- Client name display -->
        <div *ngIf="otherJobType.client.data" class="fw-bold mb-2">
          <dl>
            <dt>Client</dt>
            <dd>
              {{ otherJobType.client.value }}
            </dd>
          </dl>
        </div>
        <div class="mb-3">
          <gtapp-auto-complete
            [data]="siteList"
            placeHolder="Search Sites"
            searchKeyword="company_name"
            [initialValue]="formdata.value.siteName"
            [itemTemplate]="siteTemplate"
            [addInitialKey]="true"
            (inputChanged)="onSiteSearch($event)"
            (selected)="onSelectSite($event)"
            inputId="siteId"
          >
          </gtapp-auto-complete>
        </div>

        <ng-template #siteTemplate let-item>
          @if (item?.key === 0) {
          <div>Add New Site</div>
          } @else {
          <div>
            <div>{{ item?.company_name }}</div>
            <div class="small-font fw-bold">
              {{ item?.company?.company_name }}
            </div>
            <div class="small-font">{{ item?.address?.full_address }}</div>
          </div>
          }
        </ng-template>
        <div *ngIf="otherJobType.site?.data?.address?.latitude && !largeView">
          <gtapp-map-leaflet
            [latLon]="{
              lat: otherJobType.site?.data?.address?.latitude,
              lon: otherJobType.site?.data?.address?.longitude
            }"
            [mapId]="'largeJob'"
          ></gtapp-map-leaflet>
        </div>
      </ng-container>

      <ng-container *ngIf="currentStep === 2">
        <div class="row row-cols-2 my-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 1"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              (click)="currentStep = 3"
            >
              Next
            </button>
          </div>
        </div>

        <div class="mb-3">
          <gtapp-auto-complete
            [data]="siteContacts"
            placeHolder="Onsite Contacts (Optional)"
            searchKeyword="full_name"
            [initialValue]="formdata.value.siteContactName"
            [addInitialKey]="true"
            [itemTemplate]="siteContactTemplate"
            (selected)="selectContact($event)"
            inputId="scId"
          >
          </gtapp-auto-complete>
        </div>

        <ng-template #siteContactTemplate let-item>
          @if(item?.key ===0){
          <div>Add New Contact</div>
          }@else {
          <div>
            {{ item?.full_name }}
          </div>

          }
        </ng-template>
      </ng-container>

      <ng-container *ngIf="currentStep === 3">
        <div class="row row-cols-2 my-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 2; assigneeList = []"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              (click)="currentStep = 4"
            >
              Next
            </button>
          </div>
        </div>

        <div *ngIf="selectedStatus.enable_assignees === true" class="mb-3">
          <gtapp-auto-complete
            [data]="assigneeList"
            placeHolder="Search Guards"
            searchKeyword="full_name"
            [initialValue]="formdata.value.assigneeName"
            [itemTemplate]="assigneeTemplate"
            (inputChanged)="onAssigneeSearch($event)"
            (selected)="onAssigneeSelect($event)"
            inputId="assigneeId"
          >
          </gtapp-auto-complete>
        </div>

        <ng-template #assigneeTemplate let-item>
          <div>
            {{ item?.full_name }}
            <small *ngIf="!item?.validStateLicense">
              <i class="fa-solid fa-shield-halved"></i>
            </small>
          </div>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="currentStep === 4">
        <div class="row row-cols-2 my-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 3"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              (click)="formatScheduleForm(); currentStep = 5"
            >
              Next
            </button>
          </div>
        </div>

        <div class="mb-3">
          <textarea
            id="aiId"
            class="form-control"
            placeholder="Additional Information"
            cols="20"
            rows="10"
            wrap="hard"
            maxlength="900"
            style="width: 100%"
            type="text"
            [(ngModel)]="otherJobType.ai.value"
            formControlName="requestDetail"
          ></textarea>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep === 5">
        <div class="row row-cols-2 my-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 4"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-primary btn-lg w-100"
              (click)="onAddJob()"
              [disabled]="addJobPressed || !isScheduleFormValid()"
            >
              Add Job
            </button>
          </div>
        </div>

        <gtapp-scheduler
          *ngIf="scheduleStepperActive"
          [dialogBox]="false"
          [schedulerForm]="schedulerForm"
          [editSchedule]="true"
        ></gtapp-scheduler>
        <div class="mt-4 form-floating">
          <select
            class="form-select"
            (click)="getIntervalData()"
            [ngModel]="intervalData[0].value"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="updateInterval($event)"
            placeholder="Welfare Check Interval"
          >
            <option
              *ngFor="let interval of intervalData"
              [ngValue]="interval.value"
            >
              {{ interval.name }}
            </option>
          </select>
          <label for="">Welfare Check Interval</label>
        </div>
        <div *ngIf="intervalCheck" class="alert">
          <div class="mb-2">
            Guards will be reminded to submit a welfare check
            {{ welfareReminderDict?.[intervalCheck]  }} minutes before the
            interval set above.
          </div>
          <div>
            Maximum inactivity time allowed before a missed welfare check is
            captured:
            <span class="fst-italic"> {{ intervalCheck }} minutes. </span>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
  <div
    class="col"
    *ngIf="otherJobType.site?.data?.address?.latitude && largeView"
  >
    <gtapp-map-leaflet
      [latLon]="{
        lat: otherJobType.site?.data?.address?.latitude,
        lon: otherJobType.site?.data?.address?.longitude
      }"
      [mapId]="'largeJob'"
    ></gtapp-map-leaflet>
  </div>
</div>
