<div class="fw-bold mb-2">Add Checkpoint</div>
<div class="row row-cols-1 row-cols-lg-2">
  <div class="col">
    <form [formGroup]="checkPointForm">
      <ng-container *ngIf="currentStep === 1">
        <div class="row row-cols-2 gy-2 mb-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-secondary w-100"
              (click)="useCurrentLocation()"
            >
              My Location
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="!addressValue || !checkPointForm.value?.name"
              (click)="currentStep = 2"
            >
              Next
            </button>
          </div>
        </div>

        <div
          class="mb-2"
          *ngIf="
            !checkPointForm.value.address1 && !checkPointForm.value.address2
          "
        >
          <gtapp-auto-complete
            [data]="addressLookupData"
            placeHolder="Address Lookup"
            searchKeyword="full_address"
            [initialValue]="
              checkPointForm.value.address1 + checkPointForm.value.address2
            "
            [itemTemplate]="addressTemplate"
            [addInitialKey]="true"
            (inputChanged)="onAddressSearch($event)"
            (selected)="addressSelected($event)"
          >
          </gtapp-auto-complete>
        </div>

        <ng-template #addressTemplate let-item>
          <div *ngIf="item?.key === 0; else normalAddress">
            Enter address manually
          </div>
          <ng-template #normalAddress>
            <div>{{ item?.full_address }}</div>
          </ng-template>
        </ng-template>

        <div
          *ngIf="checkPointForm.value.address1 && checkPointForm.value.address2"
        >
          <button class="btn btn-primary w-100 mb-2" (click)="editAddress()">
            New Address
          </button>
        </div>
        <div class="form-floating mb-2">
          <input
            class="form-control"
            formControlName="name"
            id="cpName"
            type="text"
            [maxlength]="100"
            placeholder="Checkpoint Name Placeholder"
            value="Checkpoint Name Value"
          />
          <label for="cpName">Checkpoint Name</label>
        </div>
        <div *ngIf="showMap && latLon && !largeView">
          <gtapp-map-leaflet
            [latLon]="latLon"
            [mapId]="'mapid'"
            [cursorDraggable]="true"
            (emitData)="emitData($event)"
            (updateMinDistance)="updateMinDistance($event)"
          ></gtapp-map-leaflet>
          <div class="text-center">
            <em> Drag the marker to set coordinates </em>
          </div>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-dark btn-lg w-100"
            [routerLink]="['/checkpoints']"
          >
            Cancel
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep === 2">
        <div class="mb-3">
          <gtapp-auto-complete
            [data]="siteListData"
            placeHolder="Site"
            searchKeyword="company_name"
            [initialValue]="checkPointForm.value.site"
            [itemTemplate]="siteTemplate"
            [addInitialKey]="true"
            (inputChanged)="onSiteSearch($event)"
            (selected)="onSiteSelect($event)"
          >
          </gtapp-auto-complete>
        </div>

        <ng-template #siteTemplate let-item>
          @if (item?.key === 0) {
          <div>Add New Site</div>
          } @else {
          <div>
            <div class="fw-medium">
              {{ item?.company_name }} [{{ item?.company?.company_name }}]
            </div>

            <div class="small-font">{{ item?.address?.full_address }}</div>
          </div>
          }
        </ng-template>

        <div class="form-floating">
          <input
            class="form-control"
            id="description"
            formControlName="description"
            type="text"
            placeholder="Description (Optional)"
          /><label for="inputAssignee">Description (Optional)</label>
        </div>
        <div class="row row-cols-2 mt-2">
          <div class="col">
            <!-- enter key on cleint select calling these buttons. Added type to avoid that-->
            <button
              type="button"
              class="btn btn-dark btn-lg w-100"
              (click)="currentStep = 1; goBack()"
            >
              Back
            </button>
          </div>
          <div class="col" *ngIf="availableUserPrompts?.length">
            <!-- enter key on cleint select calling these buttons. Added type to avoid that-->
            <button
              type="button"
              class="btn btn-primary btn-lg w-100"
              [disabled]="!checkPointForm.valid"
              (click)="currentStep = 3"
            >
              Next
            </button>
          </div>
          <div class="col" *ngIf="!availableUserPrompts?.length">
            <!-- enter key on cleint select calling these buttons. Added type to avoid that-->
            <button
              type="button"
              class="btn btn-primary btn-lg w-100"
              [disabled]="!checkPointForm.valid"
              (click)="onClickSubmit()"
            >
              Add
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep === 3">
        <span>Post Scan Prompts</span>

        <gtapp-auto-complete
          [data]="userPromptsSearchResults"
          placeHolder="Search Prompt"
          searchKeyword="name"
          [itemTemplate]="userPromptLookupTemplate"
          (inputChanged)="searchPrompts($event)"
          (selected)="addSelectedUserPrompt($event)"
        >
        </gtapp-auto-complete>
        <ng-template #userPromptLookupTemplate let-item>
          <div>{{ item?.name }}</div>
        </ng-template>

        <div class="mt-3">
          <table class="table">
            <tbody cdkDropList (cdkDropListDropped)="dropUserPrompt($event)">
              <tr
                *ngFor="let userPrompt of availableUserPrompts"
                class="cursor-pointer"
                (click)="addRemoveUserPrompt(userPrompt)"
              >
                <td>
                  <div class="d-flex">
                    <div>
                      <div class="form-floating">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="userPrompt?.selected"
                        />
                      </div>
                    </div>
                    <div
                      class="ms-2"
                      *ngIf="
                        userPrompt?.selected &&
                        userPromptsSearchResults?.length > 1
                      "
                    >
                      <span cdkDrag [cdkDragDisabled]="!userPrompt?.selected">
                        <i class="fa-solid fa-bars cursor-move"></i>
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-2">
                      {{ userPrompt?.name }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="mt-3"
          *ngIf="
            userPromptsTotalPages > 1 &&
            userPromptsTotalCount != availableUserPrompts?.length
          "
        >
          <button
            type="button"
            class="btn btn-secondary btn-lg"
            (click)="onLoadMore()"
          >
            Load More
          </button>
        </div>

        <div class="row row-cols-2 gy-2 mt-4">
          <div class="col">
            <!-- enter key on cleint select calling these buttons. Added type to avoid that-->
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 2"
            >
              Back
            </button>
          </div>
          <div class="col">
            <!-- enter key on cleint select calling these buttons. Added type to avoid that-->
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="!checkPointForm.valid"
              (click)="onClickSubmit()"
            >
              Add
            </button>
          </div>
        </div>
      </ng-container>
    </form>
  </div>

  <div class="col">
    <div *ngIf="showMap && latLon && largeView">
      <gtapp-map-leaflet
        [latLon]="latLon"
        [mapId]="'mapid'"
        [cursorDraggable]="true"
        (emitData)="emitData($event)"
      ></gtapp-map-leaflet>
      <!-- <div class="text-center">
        Lat: {{ checkPointForm.value?.latitude }} Lon:
        {{ checkPointForm.value?.longitude }}
        
      </div> -->
      <div>
        <em> Drag the marker to set coordinates </em>
      </div>
    </div>
  </div>
</div>
