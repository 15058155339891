import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../../../environments/environment';
import { AppService } from '../../../../app.service';
import { DialogRef } from '../../../../shared/components/modal-dialogue/model-dialogue.ref';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { DataCheckService } from '../../../../shared/services/data-check.service';
import { DynamicScriptLoadService } from '../../../../shared/services/dynamic-script-load.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ProfileService } from '../../../profile/profile.service';
import { PagesService } from '../../pages.service';
import { SubscriptionService } from '../subscription.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { isLargeScreen } from '../../../../../global.variable';

declare var Square: any;

@Component({
  selector: 'gtapp-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrl: './payment-form.component.scss',
})
export class PaymentFormComponent implements OnInit {
  @Input('selectedPlan') selectedPlan: any = {};
  @Input('currentSubscription') currentSubscription: any = {};
  @Input('totalAddedUsers') totalAddedUsers: any;

  existingSlots: number = 0;

  @Output()
  emitData = new EventEmitter();

  couponCode: any;
  largeView: Boolean = isLargeScreen;
  cardProcessedData: any;
  savedPaymentCards: any;
  paymentFormInitialized: boolean = false;
  paymentProcessorConfig: any;
  processing: boolean = false;
  cardHolderName: any;

  showSqForm: boolean = false;

  dataAfterCouponApplied: any;
  showCouponInput: boolean = false;
  isCouponApplied: boolean = false;
  selectedPlanCopy: any;

  isPWAApp: boolean = Boolean(
    window.matchMedia('(display-mode: standalone)').matches
  );
  showForm: boolean = false;
  errorCouponCode: boolean = false;
  trialCoupon: boolean = false;

  allottedSlots: number = 1;
  minAllottedSlots: number = 1;
  isTrialApplicable: any;
  isSubscriberLocked: boolean = false;
  constructor(
    private spinnerService: LoadingSpinnerService,
    private scriptLoader: DynamicScriptLoadService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private profileService: ProfileService,
    private appService: AppService,
    private dataCheckService: DataCheckService,
    private toasterService: ToasterService,
    @Optional() protected dialogRef: DialogRef
  ) {
    this.spinnerService.show();
    this.getPaymentProviderConfig();
  }

  ngOnInit(): void {
    this.subscriptionService
      .getSubscriberSubscriptionStatus()
      .subscribe((response: any) => {
        this.isTrialApplicable = response['is_trial_applicable'];
        this.isSubscriberLocked = response?.lock_plan || false;
      });
    this.existingSlots = this.currentSubscription?.allotted_slots;
    if (!this.existingSlots) {
      this.calculateAllottedSlots();
    }

    this.selectedPlanCopy = JSON.parse(JSON.stringify(this.selectedPlan));

    this.getSavedCards();

    const squareScript =
      environment.production === true && environment.devMode === false
        ? 'square-script-prod'
        : 'square-script';

    this.scriptLoader
      .load(squareScript)
      .then((data) => {})
      .catch((error) => console.error(error));
    this.cardProcessedData = {};
  }
  showPaymentCardAddForm() {
    this.getSavedCards();
  }
  getSavedCards() {
    let today = new Date();
    today.setDate(1);
    today.setHours(0, 0, 0, 0);

    this.subscriptionService.getSavedCards().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.savedPaymentCards = response['data'];
        this.showForm = true;
        if (this.savedPaymentCards?.length === 0) {
          this.spinnerService.show();
          setTimeout(() => {
            this.toggleSqForm();
          }, 1000);
        } else {
          this.spinnerService.hide();
        }
        this.savedPaymentCards.forEach((e: any) => {
          let cardDate = new Date(e.expiry_year + '-' + e.expiry_month + '-01');
          if (cardDate < today) {
            e['disbleCard'] = true;
          } else {
            e['disbleCard'] = false;
          }
        });
      }
    });
  }
  async initializeSquarePayment(cardSource?: any) {
    if (this.showFullView()) {
      this.paymentFormInitialized = true;
      this.spinnerService.show();

      const payments = Square.payments(
        this.paymentProcessorConfig.app_id,
        this.paymentProcessorConfig.location_id
      );

      if (!cardSource) {
        // Initialize the card with valid custom styles
        const card = await payments.card({
          postalCode: '123456',

          //  TODO: Change the styling of card number
          // style: {
          //   input: {
          //     backgroundColor: '#333', // Dark background
          //     color: '#fff', // White text color
          //     fontSize: '16px', // Font size
          //     padding: '10px', // Padding inside the input
          //     placeholder: '#888', // Placeholder text color
          //   },
          //   invalid: {
          //     color: '#ff0000', // Red color for invalid input
          //   },
          // },
        });

        setTimeout(() => {
          card.attach('#card-container');
          this.spinnerService.hide();
        }, 100);

        const button = document.getElementById('pay-btn');
        button?.addEventListener('click', (e) => {
          this.spinnerService.show();
          e.preventDefault();

          if (!cardSource) {
            card
              .tokenize()
              .then((result: any) => {
                if (result.token) {
                  this.verifyBuyer(payments, result.token);
                } else {
                  this.spinnerService.hide();
                }
              })
              .catch((error: any) => {
                // Handle tokenization error here
                console.error('Error tokenizing card:', error);
                this.spinnerService.hide();
              });
          }
        });
      } else {
        this.verifyBuyer(await payments, cardSource);
      }
    } else {
      this.spinnerService.hide();
    }
  }

  // handle card verification response
  onCardProcessed(cardSource: any, verificationData: any) {
    this.cardProcessedData.source = cardSource;
    this.cardProcessedData.v_token = verificationData.token;
    if (verificationData.userChallenged) {
      this.spinnerService.hide();
    } else {
      this.cardProcessedData.selectedCard = this.selectedPlan.selectedCard;
      this.cardProcessedData['card_holder_name'] = this.cardHolderName;
      this.cardProcessedData['coupon_code'] = this.couponCode;

      this.processing = false;
      this.createSubscription();
    }
  }

  onCardSelected() {
    this.spinnerService.show();
    setTimeout(() => {
      this.initializeSquarePayment(this.selectedPlan?.selectedCard.card_source);
    }, 100);
  }

  async verifyBuyer(payments: any, source: any) {
    this.processing = true;
    var verificationData = {
      billingContact: {},
      intent: 'CHARGE',
      amount: `${this.selectedPlan?.final_amount}.00`,
      currencyCode: 'AUD',
    };

    payments
      .verifyBuyer(source, verificationData)
      .then((vToken: any) => {
        this.spinnerService.hide();
        this.onCardProcessed(source, vToken);
      })
      .catch((e: any) => {
        this.toasterService.setMessage({
          errorMessage: `'Card Verification Error',
          'Please try another card.'`,
          successMessage: '',
        });

        this.processing = false;
        this.spinnerService.hide();
      });
  }

  onCloseDialogue() {
    this.emitData.emit();
  }
  applyTrialCoupon() {
    this.spinnerService.show();
    var requestData = {
      plan_id: this.selectedPlanCopy?.id,
      coupon_code: this.couponCode,
    };
    this.subscriptionService
      .createSubscription(requestData)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.spinnerService.show();

          this.router.navigate(['/dashboard']).then(() => {
            window.location.reload();
          });
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            errorMessage: response['message'],
            successMessage: '',
          });
        }
      });
  }
  getFloatPaymentAmount() {
    let finalAmount: any = this.selectedPlan?.final_amount;
    let userCost: any;
    if (this.selectedPlan?.plan_type === 1) {
      if (this.allottedSlots > this.selectedPlan?.allowed_users) {
        finalAmount =
          finalAmount +
          (this.allottedSlots - this.selectedPlan?.allowed_users) *
            this.selectedPlan?.user_cost;
      }
    }
    if (this.existingSlots && this.currentSubscription) {
      userCost = this.getUserCost();
      finalAmount = this.allottedSlots * userCost;
    } else {
      if (this.dataAfterCouponApplied) {
        if (this.dataAfterCouponApplied?.details?.discount_amount) {
          finalAmount =
            finalAmount - this.dataAfterCouponApplied?.details?.discount_amount;
        }
        if (this.dataAfterCouponApplied?.details?.discount_percentage) {
          const discountPercentage =
            this.dataAfterCouponApplied.details.discount_percentage;
          const discountAmount = (finalAmount * discountPercentage) / 100;
          finalAmount -= discountAmount;
        }
      }
    }
    return finalAmount;
  }

  getPaymentAmount() {
    return this.getFloatPaymentAmount()?.toFixed(2);
  }

  getGSTAmount() {
    return (this.getPaymentAmount() * 0.1).toFixed(2);
  }

  checkCardExpiration(card: any) {
    if (card.disbleCard == true) {
      this.selectedPlan.selectedCard = null;
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'Card Expired:Select a valid card or add a new one. ',
      });
    } else {
      this.selectedPlan.selectedCard = card;
    }
  }
  toggleSqForm() {
    this.selectedPlan.selectedCard = null;
    this.cardProcessedData = {};
    this.showSqForm = true;
    setTimeout(() => {
      this.paymentFormInitialized = true;
      this.initializeSquarePayment();
    }, 100);
  }
  getPaymentProviderConfig() {
    this.subscriptionService
      .getPaymentProviderConfigs()
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.paymentProcessorConfig = response['data'];
        }
      });
  }

  onApplyCouponCode() {
    this.spinnerService.show();
    let body = {
      coupon_code: this.couponCode,
      plan_id: this.selectedPlan?.id,
    };
    this.subscriptionService
      .applyCouponCode(body)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.toasterService.setMessage({
            successMessage: 'Coupon Applied',
            errorMessage: '',
          });
          this.isCouponApplied = true;
          this.dataAfterCouponApplied = response['data'];
          if (this.dataAfterCouponApplied.effective_plan) {
            this.selectedPlan = this.dataAfterCouponApplied?.effective_plan;
          } else if (this.dataAfterCouponApplied?.coupon_type?.id == 1) {
            // this is a trial coupon , so skip the paymnet related things
            this.trialCoupon = true;
          }
          this.calculateAllottedSlots();
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: 'Invalid Coupon',
            timeOut: 1000,
          });
          this.errorCouponCode = true;
        }
        this.spinnerService.hide();
      });
  }
  removeAppliedCoupon() {
    if (this.trialCoupon === true) {
      this.spinnerService.show();
      this.initializeSquarePayment();
    }
    this.isCouponApplied = false;
    this.showCouponInput = false;

    this.trialCoupon = false;
    this.couponCode = null;
    this.dataAfterCouponApplied = null;
    this.selectedPlan = JSON.parse(JSON.stringify(this.selectedPlanCopy));
    this.calculateAllottedSlots();
  }
  forceUppercaseConditionally(event: any) {
    this.couponCode = event.target.value.toUpperCase();
  }

  getRoundOffValue(value: any, cents = true) {
    return Math.round(value * 100 * (cents ? 100 : 1)) / 100;
  }
  createSubscription() {
    if (this.existingSlots && this.currentSubscription) {
      this.spinnerService.show();
      const requestData = {
        source: this.cardProcessedData?.source,
        v_token: this.cardProcessedData?.v_token,
        cof: this.cardProcessedData?.cof,
        idempotency_key: uuidv4(),
        card_holder_name: this.cardHolderName,
        coupon_code: this.couponCode,
        allotted_slots: this.allottedSlots,
      };
      this.subscriptionService
        .addUserSlots(requestData)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.spinnerService.show();
            if (Boolean(this.existingSlots)) {
              this.router.navigate(['/users'], {
                fragment: 'userAdded',
              });
            } else {
              setTimeout(async () => {
                await this.getUserConfig();
              }, 100);
            }
          } else {
            this.spinnerService.hide();

            if (this.savedPaymentCards?.length > 0) {
              this.showSqForm = false;
              this.paymentFormInitialized = false;
            } else {
              setTimeout(() => {
                this.spinnerService.show();
                this.initializeSquarePayment();
              }, 1);
            }
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
    } else {
      this.spinnerService.show();
      var requestData = {
        plan_id: this.selectedPlanCopy?.id,
        source: this.cardProcessedData?.source,
        v_token: this.cardProcessedData?.v_token,
        cof: this.cardProcessedData?.cof,
        idempotency_key: uuidv4(),
        card_holder_name: this.cardHolderName,
        coupon_code: this.couponCode,
        allotted_slots: this.allottedSlots,
      };
      this.subscriptionService
        .createSubscription(requestData)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.spinnerService.show();

            setTimeout(async () => {
              await this.getUserConfig();
            }, 100);
          } else {
            this.spinnerService.hide();

            if (this.savedPaymentCards?.length > 0) {
              this.showSqForm = false;
              this.paymentFormInitialized = false;
            } else {
              setTimeout(() => {
                this.spinnerService.show();
                this.initializeSquarePayment();
              }, 1);
            }
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
    }
  }

  increment() {
    this.allottedSlots++;
  }

  decrement() {
    if (this.allottedSlots > 1) {
      this.allottedSlots--;
    }
  }
  async getUserConfig() {
    this.spinnerService.show();

    this.profileService
      .getUserConfig({
        remember_login: this.dataCheckService.isTrustedDevice() ? 1 : 0,
        time_offset: new Date().getTimezoneOffset(),
      })
      .subscribe((resp: any) => {
        if (resp['status'] == 'success') {
          let permissionToken = resp?.user_permission_token;
          let userData = this.appService.getUserData();
          if (permissionToken) {
            userData.user_token = permissionToken;
          }
          userData.user_token = resp?.user_permission_token;
          this.appService.setUserData(userData);
          localStorage.removeItem('firstTimeSubscriber');
          if (this.showFullView()) {
            setTimeout(() => {
              this.router.navigate(['/dashboard']);
              this.spinnerService.hide();
            }, 100);
          } else {
            window.location.reload();
          }
        }
      });
  }
  getUserCost(): number {
    let userCost;

    userCost = this.currentSubscription?.guard_price?.add_on
      ? this.currentSubscription?.guard_price?.add_on
      : this.selectedPlan?.user_cost;

    if (
      this.currentSubscription?.app_coupon?.details?.discount_percentage &&
      (this.currentSubscription?.app_coupon?.details?.repeatable === 0 ||
        this.currentSubscription?.app_coupon?.details?.repeatable >
          this.currentSubscription?.app_coupon?.usage_count)
    ) {
      const discountPercentage = parseFloat(
        this.currentSubscription.app_coupon.details.discount_percentage
      );
      const discountUserAmount: any = (userCost * discountPercentage) / 100;
      userCost -= discountUserAmount;
    }

    return userCost;
  }
  calculateAllottedSlots() {
    this.allottedSlots = Math.max(
      this.totalAddedUsers,
      this.selectedPlan?.allowed_users
    );
    this.minAllottedSlots = this.allottedSlots;
  }

  getBillingDate(subscriptionData: any) {
    if (subscriptionData?.recurring_plan) {
      const endDay = new Date(subscriptionData?.subscription_end);

      endDay.setDate(endDay.getDate() + 1);
      return endDay;
    } else {
      return subscriptionData?.subscription_end;
    }
  }
  showFullView(): boolean {
    if (this.getFloatPaymentAmount() <= 0) {
      return false;
    }
    if (this.isTrialApplicable && this.selectedPlan?.trial_days) {
      return false;
    }
    return true;
  }
}
