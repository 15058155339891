<div>
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-md-6">
        <div>
          <div class="mb-3">
            <gtapp-auto-complete
              [data]="siteList"
              placeHolder="Site"
              searchKeyword="company_name"
              [initialValue]="incidentDict.site.value"
              [itemTemplate]="siteTemplate"
              [addInitialKey]="isAdmin"
              (inputChanged)="onSiteSearch($event)"
              (selected)="onSelectSite($event)"
              id="mobsiteField"
            >
            </gtapp-auto-complete>
          </div>

          <ng-template #siteTemplate let-item>
            @if (item?.key === 0) {
            <div>Add New Site</div>
            } @else {
            <div>
              <div>{{ item?.company_name }}</div>
              <div class="small-font fw-bold">
                {{ item?.company?.company_name }}
              </div>
              <div class="small-font">{{ item?.address?.full_address }}</div>
            </div>
            }
          </ng-template>
        </div>

        <ng-container *ngIf="incidentDict.site.data">
          <div class="mb-3">
            <gtapp-auto-complete
              [data]="incidentTypeList"
              placeHolder="Incident Type"
              searchKeyword="name"
              [initialValue]="incidentDict.incidentType.value"
              [itemTemplate]="incidentTypeTemplate"
              [addInitialKey]="true"
              (inputChanged)="onSearchIncidentType($event)"
              (selected)="onSelectIncidentType($event)"
            >
            </gtapp-auto-complete>
          </div>

          <ng-template #incidentTypeTemplate let-item>
            @if (item?.key === 0) {
            <div>Add Incident Type</div>
            } @else {

            <div>{{ item?.name }}</div>

            }
          </ng-template>
          <div>
            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input
                  type="datetime-local"
                  class="form-control"
                  placeholder="Incident Took Place"
                  [(ngModel)]="incidentDict.iDay.value"
                  (change)="formateStartDate($event)"
                />
                <label>Incident Took Place</label>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="row row-cols-sm-2">
          <div class="col">
            <button
              class="btn btn-dark btn-lg w-100"
              [routerLink]="['/incidents']"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              class="btn btn-primary btn-lg w-100"
              [disabled]="
                !(
                  incidentDict.site.data &&
                  !addIncidentButtonPressed &&
                  incidentDict.incidentType.data?.id
                )
              "
              (click)="onAddIncident()"
            >
              Log Incident
            </button>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          showSiteMap &&
          incidentDict.site?.data?.address?.latitude &&
          incidentDict.site?.data?.address?.longitude
        "
        class="col-6 d-none d-md-block"
      >
        <gtapp-map-leaflet
          [latLon]="{
            lat: incidentDict.site?.data?.address?.latitude,
            lon: incidentDict.site?.data?.address?.longitude
          }"
          [mapId]="'largeJob'"
        ></gtapp-map-leaflet>
      </div>
    </div>
  </div>
</div>
