<div class="p-2 desktop-padding-margin col-xl-6 m-auto">
  <div>
    <ng-container class="col-md-12" *ngIf="showForm">
      <!-- non paymnet related view, ie when any trial coupons is applied-->
      <ng-container *ngIf="trialCoupon && isCouponApplied">
        <!-- Display the applied coupon with a remove option -->
        <div class="mt-3">
          <h5 class="plan-name">
            {{ dataAfterCouponApplied.name }}
          </h5>
        </div>
        <div class="mb-2 mt-2">
          Coupon applied for a {{ dataAfterCouponApplied?.trial_days }} day
          trial
        </div>

        <div class="d-flex flex-grow-1 me-2 mb-3">
          <span
            >Coupon {{ couponCode }}
            <span class="fw-bold text-success">(Applied)</span></span
          >
          <label class="text-danger" (click)="removeAppliedCoupon()">
            Remove
          </label>
        </div>

        <div class="row row-cols-2 mt-3">
          <div class="col">
            <button class="btn btn-dark" (click)="onCloseDialogue()">
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              class="btn btn-primary"
              id="card-pay-btn"
              (click)="applyTrialCoupon()"
            >
              Continue
            </button>
          </div>
        </div>
      </ng-container>
      <!-- payment related view-->
      <ng-container *ngIf="!trialCoupon">
        <ng-container *ngIf="existingSlots">
          <!--add user slots case-->
          <div class="mb-2">
            <div class="">
              <div class="row">
                <div class="align-self-center col-lg-4 col-sm-12">
                  Add Additional User(s) <br />
                  ${{ selectedPlan?.user_cost.toFixed(2) }}
                  each per month
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="counter-buttons">
                    <button
                      class="m-1 btn btn-outline-primary"
                      (click)="decrement()"
                      [disabled]="minAllottedSlots >= allottedSlots"
                    >
                      -
                    </button>
                    <span class="mx-3 fw-bold">{{ allottedSlots }}</span>
                    <button
                      class="m-1 btn btn-outline-primary"
                      (click)="increment()"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3 mt-1">
            <span> Total Users: </span>
            <!-- TO show the total added users :{{ totalAddedUsers }}/ -->
            <span>
              {{ existingSlots }} >>>
              <span class="fs-5">
                {{ existingSlots + allottedSlots }}
              </span>
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="!existingSlots">
          <!-- periodic subscription case-->
          <div class="mb-2">
            <h5
              class="plan-name mb-2"
              [ngStyle]="
                dataAfterCouponApplied
                  ? { color: 'var(--color-success-500)' }
                  : {}
              "
            >
              {{ selectedPlan?.name }}
            </h5>

            <div class="mb-4">
              Includes {{ selectedPlan?.allowed_users }} Users
            </div>

            <div *ngIf="selectedPlan?.trial_days && isTrialApplicable">
              <span class="fw-bold"> $0 </span>
              for the first {{ selectedPlan.trial_days }} days limited to
              {{ selectedPlan?.trial_users_allowed }} users
              <div class="my-1">then</div>
            </div>

            <div>
              <span>${{ (selectedPlan?.final_amount).toFixed(2) }}</span> per
              month
            </div>

            <ng-container *ngIf="selectedPlan?.plan_type == 2">
              <!-- transaction price payment plan-->
              <br />
              <span class="small"
                >{{ selectedPlan?.allowed_transactions }} transactions
                included</span
              >
              <br />
              <span class="small"
                >{{ getRoundOffValue(selectedPlan?.transaction_price) }}c per
                transaction thereafter</span
              >
            </ng-container>

            <ng-container *ngIf="selectedPlan?.plan_type == 1">
              <!-- user price payment plan-->
              <div>
                ${{
                  getRoundOffValue(selectedPlan?.user_cost, false).toFixed(2)
                }}
                per additional user
              </div>
              <!-- case where this is not the first subscription or there is no trial days in the plan or the user is beta-->

              <div
                class="mt-4"
                *ngIf="!selectedPlan?.trial_days || !isTrialApplicable"
              >
                <div>
                  <div class="row">
                    <div
                      class="align-self-center col-md-3 col-lg-2 col-xl-2 col-sm-12 mt-0"
                    >
                      Additional Users
                    </div>
                    <div class="col-lg-4 col-sm-9 mt-0">
                      <div class="counter-buttons">
                        <button
                          class="m-1 btn btn-outline-primary"
                          (click)="decrement()"
                          [disabled]="minAllottedSlots >= allottedSlots"
                        >
                          -
                        </button>
                        <span class="mx-3 fw-bold">{{
                          allottedSlots - selectedPlan?.allowed_users
                        }}</span>
                        <button
                          class="m-1 btn btn-outline-primary"
                          (click)="increment()"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <ng-container>
                    <div class="mb-3">
                      <!-- Headers -->
                      <div class="row row-cols-4 mb-3">
                        <div class="col-6 fw-bold"></div>
                        <div class="col-1 px-1 fw-bold text-center">Qty</div>
                        <div class="col-3 px-2 fw-bold text-end">
                          Unit Price
                        </div>
                        <div class="col-2 px-1 fw-bold text-end">Sub Total</div>
                      </div>

                      <!-- Base Plan -->
                      <div class="row row-cols-4 mb-3">
                        <div class="col-6">
                          {{ selectedPlan?.name }} <br />(Includes
                          {{ selectedPlan?.allowed_users }} Users)
                        </div>
                        <div class="col-1 px-1 text-center">1</div>
                        <div class="col-3 px-1 text-end">
                          ${{ (selectedPlan?.final_amount).toFixed(2) }}
                        </div>
                        <div class="col-2 px-1 text-end">
                          ${{ (selectedPlan?.final_amount).toFixed(2) }}
                        </div>
                      </div>

                      <!-- Additional Guards -->
                      <div
                        class="row row-cols-4 mb-3"
                        *ngIf="allottedSlots - selectedPlan?.allowed_users > 0"
                      >
                        <div class="col-6">Additional User</div>
                        <div class="col-1 px-1 text-center">
                          {{ allottedSlots - selectedPlan?.allowed_users }}
                        </div>
                        <div class="col-3 px-1 text-end">
                          ${{ getUserCost().toFixed(2) }}
                        </div>
                        <div class="col-2 px-1 text-end">
                          ${{
                            (
                              getUserCost() *
                              (allottedSlots - selectedPlan?.allowed_users)
                            ).toFixed(2)
                          }}
                        </div>
                      </div>

                      <div class="fw-bold mt-2 text-end">
                        {{ allottedSlots }} Users Total
                      </div>

                      <div class="fw-bold mt-2 text-end">
                        ${{ getPaymentAmount() }} per month
                      </div>
                    </div>
                  </ng-container>

                  <!-- <div>{{ totalAddedUsers }}</div> -->
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <div *ngIf="showFullView()">
          <div class="row row-cols-2">
            <div
              *ngIf="!showSqForm && savedPaymentCards?.length > 0"
              class="col-8 col-sm-8 col-md-9 fw-bold"
            >
              Select Card
            </div>
            <div class="row-cols-1 mx-auto">
              <button
                class="mb-0 btn btn-success"
                *ngIf="showSqForm && savedPaymentCards?.length > 0"
                (click)="showSqForm = false; paymentFormInitialized = false"
              >
                Saved Cards
              </button>
            </div>
          </div>

          <div
            *ngIf="savedPaymentCards?.length && !showSqForm"
            class="mt-1"
            [ngClass]="largeView ? 'flex-row flex-wrap' : ''"
          >
            <gtapp-saved-cards
              showOn="paymentComponent"
              (showAddCardForm)="showPaymentCardAddForm()"
              (selectCard)="checkCardExpiration($event)"
            >
            </gtapp-saved-cards>
            <br />
          </div>
          <!-- Add the coupon code input field -->

          <!-- LOAD CARDHOLDER NAME AND SQUARE CARD INPUT FIELDS -->
          <div
            *ngIf="showSqForm && !processing"
            id="card-container"
            class="mt-4"
          >
            <input
              class="form-control mb-0"
              fullWidth
              type="text"
              [(ngModel)]="cardHolderName"
              id="name"
              placeholder="Name"
              style="line-height: 2.2"
            />
          </div>

          <div class="">
            <div class="" *ngIf="showSqForm">
              Please note that we do not save your credit card details. These
              details are saved securely via our payment partner
              <a
                *ngIf="!isPWAApp"
                href="https://squareup.com/au/en/legal/general/ua"
                target="_"
                >SquareUp</a
              >
              <span *ngIf="isPWAApp">SquareUp</span>.
            </div>

            <ng-container *ngIf="isCouponApplied">
              <!-- Display the applied coupon with a remove option -->
              <div class="d-flex flex-grow-1 me-2 mb-3">
                <span
                  >Coupon {{ couponCode }}
                  <span class="fw-bold text-success">(Applied)</span></span
                >
                <label class="text-danger" (click)="removeAppliedCoupon()">
                  Remove
                </label>
              </div>
            </ng-container>
            <ng-container *ngIf="showCouponInput && !isCouponApplied">
              <div class="d-flex">
                <input
                  class="form-control flex-grow-1 me-2"
                  type="text"
                  [(ngModel)]="couponCode"
                  id="couponCode"
                  placeholder="Coupon code"
                  (input)="forceUppercaseConditionally($event)"
                />
                <button
                  class="apply-button mt-0"
                  class="btn btn-primary btn-lg"
                  (click)="onApplyCouponCode()"
                  [disabled]="!couponCode"
                >
                  Apply
                </button>
              </div>
              <div *ngIf="errorCouponCode === true" class="text-danger">
                Invalid coupon
              </div>
            </ng-container>
            <div
              class="text-end"
              *ngIf="!showCouponInput && !existingSlots && !isSubscriberLocked"
            >
              <button
                class="btn btn-secondary btn-sm mb-2"
                (click)="showCouponInput = !showCouponInput"
              >
                Apply Coupon
              </button>
            </div>

            <ng-container *ngIf="existingSlots">
              <div class="mb-3">
                <!-- Headers -->
                <div class="row row-cols-4">
                  <div class="col-6 fw-bold"></div>
                  <div class="col-1 px-1 fw-bold text-center">Qty</div>
                  <div class="col-3 px-2 fw-bold text-end">Unit Price</div>
                  <div class="col-2 px-1 fw-bold text-end">Total</div>
                </div>

                <!-- Values -->
                <div class="row row-cols-4">
                  <div class="col-6">
                    Additional User <br /><span
                      *ngIf="currentSubscription?.recurring_plan"
                      >Renews
                      {{
                        getBillingDate(currentSubscription) | date : "d MMMM y"
                      }}</span
                    >
                  </div>
                  <div class="col-1 px-1 text-center">{{ allottedSlots }}</div>
                  <div class="col-3 px-1 text-end">
                    ${{ getUserCost().toFixed(2) }}
                  </div>
                  <div class="col-2 px-1 text-end">
                    ${{ getPaymentAmount() }}
                  </div>
                </div>
              </div>
            </ng-container>

            <div *ngIf="!selectedPlan?.trial_days || !isTrialApplicable">
              <div class="d-flex justify-content-end mb-2">
                <span class="me-3">GST</span>
                <span class="amount-to-pay">${{ getGSTAmount() }}</span>
              </div>

              <div class="d-flex justify-content-end fw-bold">
                <span class="me-3">Total with GST</span>
                <span class="amount-to-pay">${{ getPaymentAmount() }}</span>
              </div>
            </div>

            <div class="text-end mt-2 small">
              Change plans later or cancel at any time
            </div>
          </div>
        </div>
        <div class="my-3">
          All pricing is inclusive of GST and in Australian Dollars
        </div>
        <div class="row row-cols-2 mt-2">
          <div class="col">
            <button
              *ngIf="!isSubscriberLocked"
              class="btn btn-dark btn-lg w-100"
              (click)="onCloseDialogue()"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <ng-container *ngIf="this.showFullView()">
              <button
                ng-container
                *ngIf="showSqForm && !processing"
                class="btn btn-primary btn-lg w-100"
                [disabled]="!cardHolderName"
                id="pay-btn"
              >
                Pay
              </button>

              <button
                *ngIf="!showSqForm"
                id="card-pay-btn "
                class="btn btn-primary btn-lg w-100"
                [disabled]="
                  !selectedPlan.selectedCard || paymentFormInitialized
                "
                (click)="onCardSelected()"
              >
                Pay
              </button>
              <div id="payment-status-container"></div>
            </ng-container>

            <div *ngIf="!this.showFullView()">
              <button
                class="btn btn-primary btn-lg w-100"
                (click)="createSubscription()"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
